<template>
  <main class="dashboard">
    <div class="d-flex pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">{{ current_page.name }}</h1>
    </div>
    <div class="text" v-html="current_page.description"></div>

    <div class="card">
      <div class="card-body">

        <div class="table-responsive">
          <table class="table dict">
            <thead>
            <tr class="">
              <th scope="col"> №</th>
              <th scope="col">Сбор упоминаний</th>
              <th scope="col">Тип упоминаний</th>
              <th scope="col">Тип автора</th>
              <th scope="col" class="mw200">Дата начала мониторинга</th>
              <th scope="col" class="mw200">Дата окончания мониторинга</th>
              <th scope="col">Частота мониторинга</th>
              <th scope="col">Дата добавления / изменения</th>
              <th scope="col">Файл отчета</th>
              <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            <tr class="empty"></tr>
            <template v-for="(object, index) in objects">
              <tr :class="object.edit?'edit':''">
                <td>{{ (index + 1) + ((page - 1) * size) }}</td>
                <template v-if="object.edit">
                  <td>
                    <div :class="object.info_area_error?'form-group--error':''">
                      <label class="d-none">Сбор упоминаний</label>
                      <select v-model="object.info_area" class="form-select">
                        <option v-for="(value, key) in select.info_area" :value="key">{{ value }}</option>
                      </select>
                    </div>
                    <div class="error" v-if="object.info_area_error">{{ object.info_area_error }}</div>
                  </td>
                  <td>
                    <div :class="object.reference_type_error?'form-group--error':''">
                      <label class="d-none">Тип упоминаний</label>
                      <select v-model="object.reference_type" class="form-select">
                        <option v-for="(value, key) in select.reference_type" :value="key">{{ value }}
                        </option>
                      </select>
                    </div>
                    <div class="error" v-if="object.reference_type_error">{{ object.reference_type_error }}</div>
                  </td>
                  <td>
                    <div :class="object.author_type_error?'form-group--error':''">
                      <label class="d-none">Тип автора</label>
                      <select v-model="object.author_type" class="form-select">
                        <option v-for="(value, key) in select.author_type" :value="key" :key="key">{{ value }}</option>
                      </select>
                    </div>
                    <div class="error" v-if="object.author_type_error">{{ object.author_type_error }}</div>
                  </td>
                  <td>
                    <label class="d-none">Дата начала мониторинга</label>
                    <div :class="'' + (object.date_start_error?'form-group--error':'')">
                      <date-picker @dp-show="clickDate($event)"
                                   @dp-change="$func.setValue(object, 'date_start_error', false)" class="date-picker"
                                   readonly style="width: 150px;" v-model="object.date_start"
                                   ref="start_date"
                                   :config="{minDate: object.analyses_type === 'online'?new Date():false, maxDate: object.analyses_type === 'retro'?new Date:false, format: 'YYYY-MM-DD', locale: 'ru', useCurrent: true, ignoreReadonly: true}"></date-picker>
                    </div>
                    <div class="error" v-if="object.date_start_error">{{ object.date_start_error }}</div>
                  </td>
                  <td>
                    <label class="d-none">Дата окончания мониторинга</label>
                    <div :class="' ' + (object.date_end_error?'form-group--error':'')">
                      <date-picker @dp-show="clickDate($event)"
                                   @dp-change="$func.setValue(object, 'date_end_error', false)" class="date-picker"
                                   readonly style="width: 150px;" v-model="object.date_end"
                                   ref="start_date"
                                   :config="{minDate: object.analyses_type == 'online'?new Date():false, maxDate: object.analyses_type == 'retro'?new Date:false, format: 'YYYY-MM-DD', locale: 'ru', useCurrent: true, ignoreReadonly: true}"></date-picker>
                    </div>
                    <div class="error" v-if="object.date_end_error">{{ object.date_end_error }}</div>
                  </td>

                  <td>
                    <div :class="object.period_error?'form-group--error':''">
                      <label class="d-none">Частота мониторинга</label>
                      <select v-model="object.period" class="form-select">
                        <option v-for="(value, key) in select.period" :value="key" :key="key">{{ value }}</option>
                      </select>

                    </div>
                    <div class="error" v-if="object.period_error">{{ object.period_error }}</div>
                  </td>
                  <td>{{ object.date }}</td>
                  <td>
                    <template v-if="object.report_name">
                      <a class="no-wrap" :href="object.report">
                        <i class="bi bi-file-text"></i>
                        {{ object.report_name }}
                      </a>
                    </template>
                    <!--                    <template v-else>-->
                    <!--                      <input :id="`report-${index}`" :ref="`report-${index}`" type="file"-->
                    <!--                             @change="setFile('report', index, object)" class="form-control" />-->
                    <!--                    </template>-->
                  </td>
                </template>
                <template v-else>
                  <td>{{ object.get_info_area_display }}</td>
                  <td>{{ object.get_reference_type_display }}</td>
                  <td>{{ object.get_author_type_display }}</td>
                  <td>{{ object.date_start }}</td>
                  <td>{{ object.date_end }}</td>
                  <td>{{ object.get_period_display }}</td>
                  <td>{{ object.date }}</td>
                  <td>
                    <template v-if="object.report_name">
                      <a class="no-wrap" :href="object.report">
                        <i class="bi bi-file-text"></i>
                        {{ object.report_name }}
                      </a>
                    </template>
                  </td>
                </template>
                <td>
                  <template v-if="!object.report_name">
                    <a href="" v-if="!object.edit" v-on:click.prevent="$func.setValue(object, 'edit', true);"
                       class="no-wrap"><i class="bi bi-pencil"></i>
                      <small>редактировать</small>
                    </a>
                    <template v-else>
                      <a href="" class="text-success ms-sm-2 no-wrap" v-on:click.prevent="saveNote(object)"><i
                          class="bi bi-check-circle"></i>
                        <small>Сохранить</small>
                      </a> <br>
                      <a href="" class="text-danger ms-sm-2 no-wrap" v-on:click.prevent="endEdit(object)"><i
                          class="bi bi-x-circle"></i>
                        <small>Отмена</small>
                      </a>
                    </template>
                  </template>
                </td>

              </tr>
            </template>
            <tr v-if="!objects.length">
              <td colspan="9">
                Не добавлено ни одной записи
              </td>
            </tr>
            <tr v-if="objects.length < max_length">
              <td colspan="9">
                <button class="btn btn-success" v-on:click="addNote" title="Добавить запись">+</button>
              </td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
      <PagerList v-bind:pager="pager" v-bind:updatePage="updatePage" v-bind:page="page" ></PagerList>


    </div>


  </main>
</template>

<script>
import dataMixin from './mixins.js';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

export default {
  name: 'MonitoringService',
  mixins: [dataMixin],
  data() {
    return {
      name: 'monitoring',
      empty: {
        info_area: '', reference_type: '', date: '', edit: true, author_type: '', date_start: '', date_end: '',
        period: ''
      },
      validate: {
        info_area: {}, reference_type: {}, author_type: {}, date_start: {}, date_end: {}, period: {}
      },
      select: {
        info_area: [], reference_type: [], author_type: [], period: []
      },
    }
  },
  methods: {
    setFile(field, index, object) {
      if (this.$refs[`${field}-${index}`][0].files) {
        this.objects[this.objects.indexOf(object)][field] = this.$refs[`${field}-${index}`][0].files[0];
      }
    },
  }
}

</script>


<style>
textarea {
  min-width: 380px;
}

.form-select {
  min-width: 150px;
}
</style>
