import { render, staticRenderFns } from "./Responses.vue?vue&type=template&id=7637f0b4"
import script from "./Responses.vue?vue&type=script&lang=js"
export * from "./Responses.vue?vue&type=script&lang=js"
import style0 from "./Responses.vue?vue&type=style&index=0&id=7637f0b4&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports