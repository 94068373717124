<template>
  <header v-if="$store.getters.isLoggedIn"
          class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow text-light">
    <div>
      <router-link class="navbar-brand col-md-3 col-lg-2 me-0 px-3" to="/">
        <img :src="`/img/${$store.getters.company.logo}.svg`" class="company-logo"/>
      </router-link>
      <button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false"
              aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>

    <ul class="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">

      <!--            <li>-->
      <!--              <a href="#" v-on:click.prevent="" title="Тариф" class="nav-link text-white">-->
      <!--                Тариф "{{user.tariff}}"-->
      <!--              </a>-->
      <!--            </li>-->
      <li v-if="show_pwa">
        <a href="" v-on:click.prevent="installPWA" title="Установить приложение" class="nav-link text-white">
          <i class="bi bi-box-arrow-down"></i>
        </a>
      </li>
      <li :class="`alert_count${has_alert?' active':''}`">
        <a href="" v-on:click.prevent="setAlertRead" title="Уведомления" data-bs-toggle="offcanvas"
           data-bs-target="#alertArea" aria-controls="alertArea" class="nav-link text-white">
          <i class="bi bi-bell"></i>
          {{ alert_count }}
        </a>
      </li>
      <li>
        <router-link to="/settings/" title="Личный кабинет" class="nav-link text-white">
          <i class="bi bi-person-circle"></i>
          {{ user.fullname }}
        </router-link>
      </li>
      <li>
        <a href="#" @click.prevent="logout" title="Выход" class="nav-link text-white">
          <i class="bi bi-box-arrow-right"></i>
          Выход
        </a>
      </li>
    </ul>

    <div class="offcanvas offcanvas-end" tabindex="-1" id="alertArea" aria-labelledby="offcanvasRightLabel">
      <div class="offcanvas-header">
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <template v-if="$store.getters.alerts.length">
          <div class="show alerts" role="alert" aria-live="assertive" aria-atomic="true">
            <div v-for="alert in $store.getters.alerts" :class="`mb-3 ${alert.read?'disabled':''}`">
              <div :class="`toast-header mb-2 border-${alert.color} bg-gradient p-1`">

                <p class="me-auto" v-html="alert.text"></p>
                <small>{{ alert.date_create }}</small>
              </div>

            </div>
          </div>

        </template>
        <template v-else>
          <div class="show">
            <div class="toast-header mb-2">
              <p class="text-dark">Нет новых уведомлений</p>
            </div>
          </div>
        </template>
      </div>
    </div>
  </header>
</template>

<script>
import {UserApi} from '@/api/user'

export default {
  name: 'HeaderBlock',
  data() {
    return {
      open_menu: false,
      open_user: false,
      project_name: '',
      has_alert: false,
      est_name: '',
      curr_project: false,
      show_pwa: false
    }
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    alert_count() {
      return this.$store.getters.alerts.filter((alert) => {
        return !alert.user || !alert.read
      }).length
    }
  },
  beforeMount() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      this.installEvent = e
      this.show_pwa = true
    })
  },
  watch: {
    alert_count() {
      let $this = this;

      $this.has_alert = true;
      setTimeout(function () {
        $this.has_alert = false;
      }, 500);
    }
  },
  methods: {
    installPWA() {
      this.installEvent.prompt()
    },
    logout: function () {
      this.$store.dispatch('logout')
          .then(() => {
            this.$router.push('/login')
          })
    },
    setAlertRead: function () {
      UserApi.read_alert().then(() => this.$store.dispatch('alert_list'))
    }
  }
}
</script>
<style scoped="">
.toast-header {
  border-left: 10px solid #ccc;
}
</style>
