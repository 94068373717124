<template>
  <main class="dashboard">
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
      <div class="btn-toolbar mb-2 mb-md-0">
        <div class="btn-group me-2" v-if="$store.getters.has_cp && $store.getters.user.user_type === 'free'">
          <router-link to="/projects/add"
                       v-if="$store.getters.user.keyword_count < $store.getters.tariff.request_count && $store.getters.tariff.can_edit" type="button"
                       class="btn btn-sm blue-bg btn-primary">Создать проект <i
              class="bi bi-plus-circle ms-1"></i>
          </router-link>
          <template v-else>
            <popper trigger="hover" :options="{ placement: 'top', modifiers: { offset: { offset: '0,10px' } } }">
              <div class="popper text-left">
                Для данной организации достигнуто максимальное количество запросов. Всего доступно:
                {{ $store.getters.tariff.request_count }}
              </div>
              <span slot="reference">
                   <button class="btn btn-sm blue-bg btn-primary" disabled>Создать проект <i
                       class="bi bi-plus-circle ms-1"></i></button>
                </span>
            </popper>
          </template>
        </div>
      </div>
    </div>

    <div class="card" v-for="project in project_snapshots">
      <div class="card-header" :style="{'background-color': 'rgb(255 255 255 / 3%)'}">
        <ul class="nav nav-tabs card-header-tabs">
          <li class="col-sm-3">
            <h4>{{ project.info.name }}</h4>
          </li>
          <li>
            <CountryChoice v-if="project && project.info.countries"
                           v-bind:active_country.sync="active_contries_for_projects[project.info.id]"
                           v-bind:project_id="project.info.id"
                           v-bind:countries="project.project_tr_countries"
                           v-bind:changeCountry="changeCountry">
            </CountryChoice>
          </li>
          <li class="d-flex flex-column mb-3 col">


            <router-link  class="d-flex flex-row justify-content-end text-dark" v-if="project.info.negative_info"
                 :to="{ name: 'project_forecast', params: { id: project.info.id }}">

              <div class="p-2"> План: <b>{{ project.info.negative_info.clf || 0 }},</b></div>
              <div class="p-2"> Факт ТОП {{ project.info.active_state }}:
                <b :class="`${((project.info.negative_info.negative_fact||0)  > (project.info.negative_info.clf||0 ))?'text-danger':((project.info.negative_info.negative_fact||0)  < (project.info.negative_info.clf||0 ))?'text-success':'text-dark'}`">
                  {{ project.info.negative_info.negative_fact || 0 }}</b>
              </div>
            </router-link>

          </li>
        </ul>
      </div>

      <div class="row">
        <detail_info v-if="project.info.country_data.find(x => x.code === active_contries_for_projects[project.info.id].code)"
            v-bind:project="project.info.country_data.find(x => x.code === active_contries_for_projects[project.info.id].code).data.project_stats.stats"
            v-bind:date="project.presentation_date"
            v-bind:state="10"
            v-bind:full_info_show="false"
            v-bind:active_country="active_contries_for_projects[project.info.id]">
        </detail_info>

        <div v-else >Такой страны нет в проекте</div>

      </div>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <router-link :to="{ name: 'project_snap_detail', params: { id: project.info.id }}"
                     class="btn btn-outline-primary btn-sm m-2">Смотреть полностью<i
            class="bi bi-arrow-right ms-2"></i></router-link>
      </div>
    </div>
    <div class="card" v-for="project in free_projects">
        <div class="card-header">
          <ul class="nav nav-tabs card-header-tabs">
            <li class="col-sm-3">
              <h4 class="link active">{{ project.name}}</h4>
            </li>
            <li v-if="project.stats" class="d-flex flex-row justify-content-end mb-3 col no-wrap">

              <div > Факт негатива
                <router-link :to="{ name: 'links_info', params: { id: project.id }, query: { state: '20',
            engine: '', tone: ['negative'], type_tone: ['new', 'offer'] }}">
                  <b class="text-danger">
                    {{project.stats.negative.negative_20}}</b>
                </router-link>

              </div>
            </li>
          </ul>

        </div>
        <template v-if="project.stats">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6">
                <info_block v-bind:data="project.stats.negative" v-bind:status="`danger`" v-bind:image_info="0"
                            v-bind:project_id="project.id"></info_block>

              </div>
              <div class="col-sm-6">
                <info_block v-bind:data="project.stats.positive" v-bind:status="`success`" v-bind:image_info="0"
                            v-bind:project_id="project.id"></info_block>
              </div>
            </div>

          </div>



        </template>
         <div class="d-grid gap-2 d-md-flex justify-content-md-end">
            <router-link :to="{ name: 'project_detail', params: { id: project.id }}"
                         class="btn btn-outline-primary btn-sm m-2">Смотреть полностью<i
              class="bi bi-arrow-right ms-2"></i></router-link>
          </div>
      </div>

  </main>
</template>

<script>
import {ProjectApi} from '@/api/project'
import Popper from 'vue-popperjs';
import InfoBlock from '@/components/includes/InfoBlock.vue';
import CountryChoice from '@/components/CountryChoice';
import sermDetail from "@/components/project/Serm.vue";


export default {
  name: 'FrontPage',
  components: {
    'popper': Popper, 'info_block': InfoBlock,
    CountryChoice, 'detail_info': sermDetail
  },

  data() {
    return {
      projects: [],
      project_snapshots: [],
      active_contries_for_projects: {}
    }
  },
  computed: {
    search: {
      get: function () {
        return this.$store.getters.search;
      },
      set: function () {
      }
    },
    free_projects: {
      get: function () {
        return this.$store.getters.projects;
      },
      set: function () {
      }
    },
  },
  mounted: function () {
    ProjectApi.get_project_list().then(response => {
      this.project_snapshots = response
      let countries = {}
      for (let project of this.project_snapshots) {
        countries[project.info.id] = project.info.active_country_code
      }
      this.active_contries_for_projects = countries
    });
  },

  methods: {
    changeCountry: function (country, project_id) {
      this.active_contries_for_projects[project_id] = country
    },

  }
}

</script>

<style scoped>
.card {
  margin-bottom: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  border: 0;
}

</style>
