<template>

  <main class="">
    <div class="form-signin">
      <div class="breadcrumb">
        <a :href="$store.getters.current_domain">Главная</a> <span>Регистрация</span>
      </div>
      <div class="">
        <form @submit.prevent="change_password" class="">
          <h1 class="h3 mb-3 fw-normal">Изменение пароля</h1>
          <transition name="fade">
            <div class="card" style="height: 18rem" v-if="reg_end">

              <div class="card-body text-center">
                <h4 class="card-text mb-3">
                  Пароль успешно изменён
                </h4>
                <p>Можете авторизоваться в сервисе используя свой email и пароль</p>
                <small>
                  Вы будете автоматически перенаправлены на вход через
                  <strong>{{currentTime}}</strong>
                </small>
              </div>

            </div>
          </transition>
          <transition name="fade">
            <div class="card" v-if="errors.length">

              <div class="card-body text-center border border-danger" style="padding: 10px 20px">
                <h4 class="card-text mb-3" v-for="error in errors">
                  {{error}}
                </h4>

              </div>

            </div>
          </transition>

          <template v-if="can_change && !errors.length && !reg_end">


            <div :class="'form-floating mt-2 ' + ($v.password.$error?'form-group--error':'')">
              <input class="form-control" :type="show_pass?'text':'password'" v-model.trim="$v.password.$model"
                     placeholder="Пароль">
              <label>Новый пароль</label>
              <i v-on:click="show_pass=!show_pass" style="right: 40px"
                 :class="`bi bi-eye${show_pass?' active':''}`"></i>
              <span class="bi-place">
            <popper trigger="click" :options="{ placement: 'right',modifiers: { offset: { offset: '0,10px' } } }">
                <div class="popper text-left">
                 Введите пароль используя буквы латинского алфавита A-Z, a-z, цифры 0-9 и служебные символы;
                </div>
                <span slot="reference">
                   <i :class="`bi bi-info-circle`"></i>
                </span>
            </popper>
          </span>
            </div>
            <div class="form-group__message">
            <div class="error" v-if="!$v.password.required && $v.password.$error">- Введите пароль</div>
            <div class="error" v-if="!$v.password.minLength && $v.password.$error">
              - Пароль должен состоять минимум из {{$v.password.$params.minLength.min}} символов
            </div>
              <div class="error" v-if="!$v.password.truePassword && $v.password.$error">
                  - Пароль должен содержать заглавные и строчные буквы, цифры и специальные символы
                </div>
            </div>


            <div :class="'form-floating mt-2 ' + ($v.repeat_password.$error?'form-group--error':'')">
              <input class="form-control" :type="show_pass?'text':'password'" v-model.trim="$v.repeat_password.$model"
                     placeholder="Повторите пароль">
              <label>Повторите пароль</label>
              <i v-on:click="show_pass=!show_pass" style="right: 40px"
                 :class="`bi bi-eye${show_pass?' active':''}`"></i>
            </div>
            <div class="form-group__message">
            <div class="error" v-if="!$v.repeat_password.required && $v.repeat_password.$error">- Введите пароль</div>
            <div class="error"
                 v-if="!$v.repeat_password.sameAsPassword && $v.repeat_password.$error && repeat_password.length">
             - Введённые пароли не совпадают
            </div>
            </div>


            <button class="w-100 btn btn-lg btn-primary mt-3" type="submit">Восстановить пароль</button>
          </template>
        </form>
        <div class="mt-3 text-center">
          <router-link to="/registration/">Регистрация</router-link>
          |
          <router-link to="/login/">Вход</router-link>
        </div>
      </div>
    </div>
  </main>

</template>
<script>
  import {required, minLength, sameAs} from 'vuelidate/lib/validators'
  import Popper from 'vue-popperjs';
  import 'vue-popperjs/dist/vue-popper.css';
  import {UserApi} from '../../api/user'
const truePassword = (value) => /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\W\w])[A-Za-z\d\W\w]{8,}$/.test(value);
  export default {

    name: 'SetPassword',
    data() {
      return {
        password: "",
        repeat_password: "",
        show_pass: false,
        errors: [],
        can_change: true,
        currentTime: 5,
        timer: null,
        reg_end: false,
      }
    },
    components: {
      'popper': Popper
    },
    validations: {
      password: {
        required,
        truePassword,
        minLength: minLength(8)
      },
      repeat_password: {
        required,
        sameAsPassword: sameAs('password')
      },
    },
    destroyed() {
      this.stopTimer()
    },
    mounted: function () {
      let $this = this;
      UserApi.check_token(this.$route.params.uid, this.$route.params.token).then(response => {
        if (response.data['errors']) {
          $this.errors = response.data.errors;
        }
        else $this.can_change = true;
      });
    },
    methods: {
      change_password: function (e) {
        e.preventDefault();
        let $this = this;
        this.$v.$touch();
        if (!this.$v.$invalid) {
          UserApi.change_password({uid: this.$route.params.uid, token: this.$route.params.token, password: this.password}).then(response => {
            if (response.data['errors']) {
              for (let i in response.data['errors']) {
                $this.$notify({
                  group: 'alert',
                  type: 'error ',
                  text: response.data['errors'][i]
                })
              }
            }
            else {
              $this.reg_end = true;
              this.startTimer();
            }
          });

        }
      },
      startTimer() {
        this.timer = setInterval(() => {
          this.currentTime--
        }, 1000)
      },
      stopTimer() {
        clearTimeout(this.timer)
      },
    },
    watch: {
      currentTime(time) {
        if (time === 0) {
          this.stopTimer();
          this.$router.push({name: "login"});
        }
      }
    },
  }
</script>
<style scoped>

  main {
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #f5f5f5;
    height: 100%;
  }

  .form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
  }


</style>
