import axios from 'axios'
import router from '../router/index'
import {UserApi} from "@/api/user";

const DOMAINS = [
    {
        domain: 'orion-solutions',
        logo: 'orion',
        favicon: 'orion.svg',
        name: "Orion Solution",
        styles: ['orion'],
        pwa: {
            icons: "orion_icons"
        }
    },
    {
        domain: 'ton-reputation',
        favicon: 'ton.png',
        logo: 'logo',
        name: "Ton Reputation",
        styles: [],
        pwa: {
            icons: 'icons'
        }
    },
    {
        domain: 'localhost',
        logo: 'orion',
        favicon: 'orion.svg',
        name: "Orion Solution",
        styles: ['orion'],
        pwa: {
            icons: "orion_icons"
        }
    }]
const User = {
    state: {
        user: {},
        tariff: {},
        is_auth: localStorage.getItem('is_auth') || false,
        has_cp: localStorage.getItem('has_cp') || false,
        remember: localStorage.getItem('remember') || false,
        user_type: localStorage.getItem('user_type') || false,
        username: null,
        alerts: [],
        jwt: localStorage.getItem("token") || null,
        pages: JSON.parse(localStorage.getItem('pages') || '[]')
    },
    mutations: {
        auth_success(state, user) {
            state.user = user;
            state.is_auth = true;
            localStorage.setItem("is_auth", true);
            localStorage.setItem("has_cp", user.has_cp);
            localStorage.setItem("user_type", user.user_type);
            if (state.remember) {
                localStorage.setItem("username", user.username);
                state.username = user.username;
            } else {
                localStorage.removeItem("username");
                state.username = null;
            }
            this.dispatch('get_tariff');
            this.dispatch('alert_list');
            this.dispatch('pages');

        },
        set_tariff(state, tariff) {
            state.tariff = tariff;
        },
        set_pages(state, pages) {
            state.pages = pages;
            localStorage.setItem("pages", JSON.stringify(pages));
        },
        alert_list(state, tariff) {
            state.alerts = tariff;
        },
        updateToken(state, newToken) {
            // TODO: For security purposes, take localStorage out of the project.
            localStorage.setItem("token", newToken);
            state.jwt = newToken;
        },
        logout(state) {
            state.user = {};
            localStorage.removeItem("is_auth");
            localStorage.removeItem("token");
            state.is_auth = false;
            state.jwt = null;
        },
        setRemember(state, remember) {
            if (remember) localStorage.setItem("remember", remember);
            else localStorage.removeItem("remember");
            state.remember = remember;
        },
    },
    actions: {
        login({commit, dispatch}, user) {
            return new Promise((resolve, reject) => {
                axios.post('/auth/obtain_token/', user).then((response) => {
                    commit("updateToken", response.data.token);
                    dispatch('get_user');
                    dispatch('projects');
                    setTimeout(function () {
                        location.reload();
                    }, 300);

                    resolve(response);
                })
                    .catch((error) => {
                        console.log(error);
                        reject(error)
                    });
            })
        },

        refresh_token({commit, dispatch}) {
            let $this = this;
            if ($this.getters.jwt) return new Promise((resolve, reject) => {
                axios.post('/auth/refresh_jwt_token/', {token: $this.getters.jwt}).then((response) => {
                    commit("updateToken", response.data.token);
                    dispatch('get_user');
                    resolve(response);
                }).catch((error) => {
                    // commit('logout');
                    reject(error)
                })
            })
        },
        get_user({commit, getters}) {
            let $this = this;
            return new Promise((resolve) => {
                const base = {
                    baseURL: getters.api_path,
                    headers: {
                        Authorization: `JWT ${$this.getters.jwt}`,
                        "Content-Type": "application/json",
                    },
                    xhrFields: {
                        withCredentials: true,
                    },
                };
                const axiosInstance = axios.create(base);
                axiosInstance({
                    url: "/users/get_info/",
                    method: "get",
                    params: {},
                }).then((response) => {
                    response.data.path = '/';
                    commit('auth_success', response.data);

                    resolve(response);
                });
            })
        },
        update_user({dispatch}, user) {
            UserApi.update_user(user).then(() => dispatch('get_user'));

        },
        get_tariff({commit, getters}) {
            let $this = this;
            return new Promise((resolve,) => {
                const base = {
                    baseURL: getters.api_path,
                    headers: {
                        Authorization: `JWT ${$this.getters.jwt}`,
                        "Content-Type": "application/json",
                    },
                    xhrFields: {
                        withCredentials: true,
                    },
                };
                const axiosInstance = axios.create(base);
                axiosInstance({
                    url: "/users/get_tariff/",
                    method: "get",
                    params: {},
                }).then((response) => {
                    commit('set_tariff', response.data);

                    resolve(response);
                });
            })
        },
        alert_list({getters}) {
            let $this = this;
            return new Promise((resolve) => {
                const base = {
                    baseURL: getters.api_path,
                    headers: {
                        Authorization: `JWT ${$this.getters.jwt}`,
                        "Content-Type": "application/json",
                    },
                    xhrFields: {
                        withCredentials: true,
                    },
                };
                const axiosInstance = axios.create(base);
                axiosInstance({
                    url: "/users/alert_list/",
                    method: "get",
                    params: {},
                }).then((response) => {
                    // commit('alert_list', response.data);

                    resolve(response);
                });
            })
        },
        pages({commit, getters}) {
            let $this = this;
            return new Promise((resolve) => {
                const base = {
                    baseURL: getters.api_path,
                    headers: {
                        Authorization: `JWT ${$this.getters.jwt}`,
                        "Content-Type": "application/json",
                    },
                    xhrFields: {
                        withCredentials: true,
                    },
                };
                const axiosInstance = axios.create(base);
                axiosInstance({
                    url: "/users/services/",
                    method: "get",
                    params: {},
                }).then((response) => {
                    commit('set_pages', response.data);
                    resolve(response);
                });
            })
        },
        logout({commit}) {
            return new Promise((resolve) => {
                axios.get('/auth/logout/')
                    .then(() => {
                        commit('logout');
                        router.push('/login/');
                        resolve()
                    })

            })
        },
        go_404() {
            router.push({name: '404'});
        },
        go_403() {
            router.push({name: '403'});
        },
    },
    getters: {
        isLoggedIn: state => state.is_auth,
        has_cp: state => JSON.parse(state.has_cp),
        user: state => state.user,
        user_type: state => state.user_type,
        tariff: state => state.tariff,
        jwt: state => state.jwt,
        alerts: state => state.alerts,
        pages: state => state.pages,
        api_path: () => process.env.VUE_APP_DEV_MODE?process.env.VUE_APP_ROOT_API:`${location.protocol}//api.${location.host}/`,
        company: () => DOMAINS.filter(x=>location.host.includes(x.domain))?.[0] || DOMAINS[0],
        current_domain: () => location.protocol+"//"+location.hostname.split('.').slice(-2).join('.')
    }
};

export default User;
