<template>
  <main class="dashboard">


    <div class="not-found">
        <h1>404</h1>
        <h3>Страница не найдена</h3>
        <p>
          <router-link to="/"><i class="bi bi-arrow-left-short"></i> Вернуться на главную</router-link>
        </p>
    </div>


  </main>
</template>
<script>


export default {
  name: 'PageNotFound'
}
</script>
<style scoped>

  main {
    display: flex;
    align-items: center;
    /*padding-top: 40px;*/
    /*padding-bottom: 40px;*/
    /*background-color: #f5f5f5;*/
    /*height: 100%;*/
  }

  .not-found {
    width: 100%;
    text-align: center;
  }



</style>

