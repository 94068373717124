<template>

  <div id="app" class="bg-light">
    <notifications group="alert"/>
    <span class="loader" v-if="$store.getters.load">
    <div class="spinner-border text-primary" role="status">
  <span class="visually-hidden">Loading...</span>
</div></span>

    <template
        v-if="$store.getters.isLoggedIn && $route.name != 'login' && $route.name != 'registration' && $route.name != 'forgot' && $route.name != 'registration_activate'">


      <navigation/>
      <div class="container-fluid h100">
        <div class="h100">
          <left_menu/>
          <router-view/>
        </div>


      </div>
    </template>
    <template v-else>
      <router-view/>
    </template>
  </div>
</template>

<script>
import Navigation from '@/components/includes/Header'
import Menu from '@/components/includes/Menu'
import {useFavicon} from '@vueuse/core'
import store from "@/store";
import axios from "axios";

const icon = useFavicon()

icon.value = `/img/${store.getters.company.favicon}`

export default {
  name: 'App',
  components: {
    Navigation, 'left_menu': Menu,
  },
  mounted() {
    let url = new URL(location)
    const hash = url.searchParams.get('hash');
    if (hash) {
      const parse_hash = JSON.parse(atob(hash))
      const base = {
        baseURL: this.$store.getters.api_path,
        headers: {
          Authorization: `JWT ${parse_hash.jwt}`,
          "Content-Type": "application/json",
        },
        xhrFields: {
          withCredentials: true,
        },
      };
      const axiosInstance = axios.create(base);
      axiosInstance({
        url: "/staff/organization_login/",
        method: "post",
        data: parse_hash,
      }).then((response) => {
        this.$store.commit("updateToken", response.data.token);
        this.$store.dispatch('get_user');
        setTimeout(() => location = '/', 300)
      }).catch(err => {
        this.$notify({
          group: 'alert',
          type: 'error ',
          text: err.response.data.error
        });
        setTimeout(() => location = '/', 300)
      });
    }
  },
  created: function () {
    let $this = this;
    this.$store.dispatch('refresh_token');

    setInterval(function () {
      $this.$store.dispatch('refresh_token');
    }, 1000 * 60 * 5);
    this.$store.dispatch('projects');
  }
}
</script>
<style>
.h100 {
  height: 100%;
}

body {
  background: #F5F6FA;
  min-height: 75rem;
}

.loader {
  position: fixed;
  bottom: 10px;
  right: 20px;
  z-index: 1000;
}
</style>
