<template>
  <main class="dashboard">
    <div class="d-flex pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">{{ current_page.name }}</h1>
    </div>
    <div class="text" v-html="current_page.description"></div>

    <div class="card">
      <div class="card-header">
        <div class="btn-toolbar mb-2 mb-md-0">
          <div class="form-floating ms-2">
            <select class="form-select" id="info_area" v-model="filters.info_area" aria-label="Тип площадки">
              <option value=""></option>
              <option v-for="(name, key) in select.info_area" :value="key">{{ name }}</option>
            </select>
            <label for="info_area">Тип площадки</label>
          </div>
          <div class="form-floating ms-2">
            <select class="form-select" id="reference_type" v-model="filters.reference_type"
                    aria-label="Тип упоминания">
              <option value=""></option>
              <option v-for="(name, key) in select.reference_type" :value="key">{{ name }}</option>
            </select>
            <label for="reference_type">Тип упоминания</label>
          </div>
          <div class="form-floating ms-2">
            <select class="form-select" id="author_type" v-model="filters.author_type" aria-label="Тип автора">
              <option value=""></option>
              <option v-for="(name, key) in select.author_type" :value="key">{{ name }}</option>
            </select>
            <label for="author_type">Тип автора</label>
          </div>
          <div class="form-floating ms-2">
            <select class="form-select" id="tone" @change="onChangeTone($event)" aria-label="Тональность" key="tone">
              <option value=""></option>
              <option value="negative">Не целевая</option>
              <option value="positive">Целевая</option>
            </select>
            <label for="tone">Тональность</label>
          </div>
          <div class="form-floating ms-2">

            <date-picker @dp-change="$func.setValue(filters, 'publication_date_after', $event.target.value)"
                         class="date-picker"
                         style="width: 150px;"
                         value=""
                         :config="dateConfig"></date-picker>
            <label for="tone">Дата "C"</label>

          </div>
          <div class="form-floating ms-2">
            <date-picker @dp-change="$func.setValue(filters, 'publication_date_before',  $event.target.value)"
                         class="date-picker"
                         value=""
                         style="width: 150px;"
                         :config="dateConfig"></date-picker>
            <label for="tone">Дата "ПО"</label>
          </div>
          <button type="button" class="btn btn-primary ms-2" @click="page=1;getData()">Показать</button>
        </div>
      </div>
      <div class="card-body">

        <div class="table-responsive">
          <table class="table dict">
            <thead>
            <tr class="">
              <th scope="col"> №</th>
              <th scope="col">Тип площадки</th>
              <th scope="col">Тип упоминания</th>
              <th scope="col">Тип автора</th>
              <th scope="col">Текст</th>
              <th scope="col">Тональность</th>
              <th scope="col">Дата публикации</th>
            </tr>
            </thead>
            <tbody>
            <tr class="empty"></tr>
            <template v-for="(object, index) in objects">
              <tr :class="object.edit?'edit':''">
                <td>{{ (index + 1) + ((page - 1) * size) }}</td>
                <td>{{ select.info_area[object.info_area] }}</td>
                <td>{{ select.reference_type[object.reference_type] }}</td>
                <td>{{ select.author_type[object.author_type] }}</td>
                <td><a href="" @click.prevent="detailPost(object.id)">{{ object.content_preview }}...</a></td>
                <td>
                  <a href="" @click.prevent="updateTone(object)">
                    <template v-if="object.is_negative !== null">
                    <span v-if="!object.is_negative"
                          class="badge text-bg-success">Целевая</span>
                      <span v-else-if="object.is_negative"
                            class="badge text-bg-danger">Не целевая</span>
                    </template>
                    <span v-else
                          class="badge text-bg-secondary">Не размечено</span>
                  </a>
                </td>
                <td>{{ $func.momentDate(object.publication_date, "DD.MM.YYYY") }}</td>
              </tr>
            </template>
            <tr v-if="!objects.length">
              <td colspan="9">
                Не добавлено ни одной записи
              </td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
      <PagerList v-bind:pager="pager" v-bind:updatePage="updatePage" v-bind:page="page"></PagerList>

    </div>
    <modal name="content" :scrollable="true" :height="'auto'" :width="`70%`" style="margin-top: 50px;">
      <div class="modal-content">
        <div class="modal-header p-2">
          <button type="button" class="btn-close" @click="$modal.hide('content');detail=null"
                  data-bs-dismiss="modal"
                  aria-label="Close"></button>
        </div>
        <div class="modal-body flex">
          <div class="card" v-if="detail">
            <div class="card-body pb-5">
              <article class="blog-post">
                <p v-html="detail.content"></p>
              </article>
            </div>
          </div>
        </div>

      </div>
    </modal>


  </main>
</template>

<script>
import dataMixin from './mixins.js';
import {DictionaryApi} from "@/api/dictionary";
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

export default {
  name: 'TonalityService',
  mixins: [dataMixin],
  data() {
    return {
      name: 'tonality',
      detail: null,
      select: {
        info_area: [], reference_type: [], author_type: [], external_tone: []
      },
      filters: {info_area: '', reference_type: '', author_type: '', external_tone: ''},
      dateConfig: {format: 'DD.MM.YYYY', locale: 'ru', useCurrent: true, ignoreReadonly: true}
    }
  },
  methods: {
    detailPost(post_id) {
      let $this = this;
      DictionaryApi.post_content(this.name, post_id).then(response => {
        $this.detail = response
        $this.$modal.show('content');
      });
    },
    updateTone(object){
      let new_val = !object.is_negative;
      DictionaryApi.update(this.name, object.id, {is_negative: new_val}).then(() =>  object.is_negative = new_val).catch(()=>{
        this.$notify({
              group: 'alert',
              type: 'error ',
              text: 'Произошла ошибка, попробуйте позднее'
            });
      });
    },
    onChangeTone(ev) {
      let value = ev.target.value, $this = this;
      switch (value) {
        case "negative":
          $this.filters.is_negative = true;
          break;
        case "positive":
          $this.filters.is_negative = false;
          break;
        default:
          delete $this.filters.is_negative
          break;
      }

    }
  }
}

</script>


<style>
textarea {
  min-width: 380px;
}

.form-select {
  min-width: 150px;
}
</style>
