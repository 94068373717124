<template>
  <main class="dashboard">
    <div class="d-flex pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">{{ current_page.name }}</h1>
    </div>
    <div class="text" v-html="current_page.description"></div>

    <div class="card">

      <template v-if="detail">
        <div
            class="card-header d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <div style="max-width: 90%">
            <h3><a :href="detail.link">{{ decodeURI(detail.link) }}</a></h3>
          </div>

          <button class="btn" @click="detail=null"><b class="h3"><i class="bi bi-x-lg"></i></b>
          </button>
        </div>
        <div class="card-body">
          <ul class="nav nav-tabs">

            <li class="nav-item">
              <button @click="page_type='request'" :class="`${page_type==='request'?'active':''} nav-link ps-2 pe-2`">
                Размещение контента
              </button>
            </li>
            <li class="nav-item">
              <button @click="page_type='content'" :class="`${page_type==='content'?'active':''} nav-link ps-2 pe-2`">
                Контент статьи
              </button>
            </li>
          </ul>
          <template v-if="page_type === 'request'">
            <WikiRequests :objects="detail.request" :wiki_id="detail.id"></WikiRequests>
          </template>
          <template v-if="page_type === 'content'">
            <WikiChange :active_dates="detail.active_dates" :wiki_id="detail.id"></WikiChange>
          </template>
        </div>

      </template>
      <div v-else class="card-body">

        <div class="table-responsive">
          <table class="table dict">
            <thead>
            <tr>
              <th scope="col"> №</th>
              <th scope="col"> Ссылка на Wikipedia</th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            <tr class="empty"></tr>
            <template v-for="(object, index) in objects">
              <tr :class="object.edit?'edit':''">
                <td>{{ (index + 1) + ((page - 1) * size) }}</td>
                <template v-if="object.edit">
                  <td>
                    <div :class="object.link_error?'form-group--error':''">
                      <label class="d-none">Ссылка на Wikipedia</label>
                      <input v-focus class="form-control" type="url" v-model="object.link"
                             v-on:keyup="$func.setValue(object, 'link_error', false)">
                    </div>
                    <div class="error" v-if="object.link_error">{{ object.link_error }}</div>
                  </td>
                </template>
                <template v-else>
                  <td><a :href="object.link" target="_blank">{{ decodeURI(object.link) }}</a></td>
                </template>

                <td>
                  <p v-if="object.id">
                    <b>Текстов к размещению:</b> <span>{{ object.request_count }}</span><br>
                    <b>Последнее обновление статьи:</b> <span>{{ object.last_update }}</span>
                  </p>
                </td>
                <td>
                  <template v-if="!object.edit">
                    <a href="" v-on:click.prevent="$func.setValue(object, 'edit', true);"
                       class="no-wrap"><i class="bi bi-pencil"></i>
                      <small>редактировать</small>
                    </a>
                    <br>
                    <button class="btn btn-primary btn-sm" style="max-width: 150px;" @click="getDetail(object)">
                      Подробнее <i
                        class="bi bi-caret-down"></i></button>
                  </template>
                  <template v-else>
                    <a href="" class="text-success ms-sm-2 no-wrap" v-on:click.prevent="saveNote(object)"><i
                        class="bi bi-check-circle"></i>
                      <small>Сохранить</small>
                    </a> <br>
                    <a href="" class="text-danger ms-sm-2 no-wrap" v-on:click.prevent="endEdit(object)"><i
                        class="bi bi-x-circle"></i>
                      <small>Отмена</small>
                    </a>
                  </template>
                  <br>
                </td>

              </tr>
            </template>
            <tr v-if="!objects.length">
              <td colspan="4">
                Не добавлено ни одной записи
              </td>
            </tr>
            <tr v-if="objects.length < max_length">
              <td colspan="5">
                <button class="btn btn-success" v-on:click="addNote" title="Добавить запись">+</button>
              </td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
      <PagerList v-bind:pager="pager" v-bind:updatePage="updatePage" v-bind:page="page"></PagerList>


    </div>


  </main>
</template>

<script>
import dataMixin from '../mixins.js';
import {DictionaryApi} from "@/api/dictionary";
import WikiRequests from "@/components/dictionary/wikipedia/WikiRequests.vue";
import WikiChange from "@/components/dictionary/wikipedia/WikiChange.vue";

export default {
  name: 'WikipediaService',
  mixins: [dataMixin],
  components: {WikiRequests, WikiChange},
  data() {
    return {
      name: 'wikipedia',
      detail: null,
      page_type: "request",
      empty: {link: '', date: '', edit: true},
      validate: {
        link: {
          func: (o) => {
            return !o.link || !this.$func.isURL(o.link) || !o.link.includes("wikipedia")
          }, error: 'Неверный формат ссылки'
        },
        // text: {},
      }
    }
  },
  methods: {
    getDetail(object) {
      DictionaryApi.get(this.name, object.id).then(resp => this.detail = resp)
      return object
    },

  }
}
</script>

<style>
textarea {
  min-width: 380px;
}
</style>
