<template>
  <main class="dashboard">
        <div class="d-flex pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">{{current_page.name}}</h1>
    </div>
    <div class="text" v-html="current_page.description"></div>

    <div class="card">
      <div class="card-body">

        <div class="table-responsive">
          <table class="table dict">
            <thead>
            <tr>
              <th scope="col"> №</th>
              <th scope="col" class="mw200">Ссылка на аккаунт в соц сети</th>
              <th scope="col">Текст поста </th>
              <th scope="col" class="mw200">Изображение или видео </th>
              <th scope="col">Дата добавления / изменения</th>
              <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            <tr class="empty"></tr>
            <template v-for="(object, index) in objects">
              <tr :class="object.edit?'edit':''">
                <td>{{(index + 1) + ((page - 1) * size)}}</td>
                <template v-if="object.edit">
                  <td>
                    <div :class="object.link_error?'form-group--error':''">
                       <label class="d-none">Ссылка на аккаунт в соц сети</label>
                      <input class="form-control" v-focus type="url" v-model="object.link"
                             v-on:keyup="$func.setValue(object, 'link_error', false)">
                    </div>
                    <div class="error" v-if="object.link_error">{{object.link_error}}</div>
                  </td>
                  <td>
                    <div :class="object.text_error?'form-group--error':''">
                       <label class="d-none">Текст поста</label>
                      <textarea class="form-control" name="" cols="50" rows="10"
                                v-on:keyup="$func.setValue(object, 'text_error', false)"
                                v-model="object.text"></textarea>
                    </div>
                    <div class="error" v-if="object.text_error">{{object.text_error}}</div>
                  </td>
                  <td>
                    <label class="d-none">Изображение или видео</label>
                    <div :class="'d-flex no-wrap'+(object.media_error?' form-group--error':'')">
                      <!--$func.setValue(object, 'media', false)-->

                      <input :id="`media-${index}`" :ref="`media-${index}`" type="file" class="d-none"
                             v-on:change="setFile('media', index, object)"/>
                      <input readonly type="text" :ref="`media_name-${index}`" class="file-name-upload"
                             v-on:change="$func.setValue(object, 'media_error', false)" v-model="object.media"/>
                      <label class="btn btn-primary" :for="`media-${index}`" title="Загрузить"><i
                        class="bi bi-file-arrow-up"></i></label>
                      <label class="btn btn-danger" v-if="object.media" v-on:click="object.media = ''" title="Удалить"><i class="bi bi-x"></i></label>
                    </div>
                    <div class="error" v-if="object.media_error">{{object.media_error}}</div>
                  </td>

                </template>
                <template v-else>
                  <td><a :href="object.link" target="_blank">{{object.link}}</a></td>
                  <td class="text-area">{{object.text}}</td>
                  <td>{{object.media}}</td>
                </template>

                <td>{{object.date}}</td>
                <td>
                  <a href="" v-if="!object.edit" v-on:click.prevent="$func.setValue(object, 'edit', true);"
                     class="no-wrap"><i class="bi bi-pencil"></i>
                    <small>редактировать</small>
                  </a>
                  <template v-else>
                    <a href="" class="text-success ms-sm-2 no-wrap" v-on:click.prevent="saveNote(object)"><i
                      class="bi bi-check-circle"></i>
                      <small>Сохранить</small>
                    </a> <br>
                    <a href="" class="text-danger ms-sm-2 no-wrap" v-on:click.prevent="endEdit(object)"><i
                      class="bi bi-x-circle"></i>
                      <small>Отмена</small>
                    </a>
                  </template>
                </td>

              </tr>
            </template>
            <tr v-if="!objects.length">
              <td colspan="6">
                Не добавлено ни одной записи
              </td>
            </tr>
            <tr v-if="objects.length < max_length">
              <td colspan="6">
                <button class="btn btn-success" v-on:click="addNote" title="Добавить запись">+</button>
              </td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
     <PagerList v-bind:pager="pager" v-bind:updatePage="updatePage" v-bind:page="page" ></PagerList>


    </div>


  </main>
</template>

<script>
  import dataMixin from './mixins.js';

  export default {
    name: 'SocialsService',
    mixins: [dataMixin],
    data() {
      return {
        name: 'socials',
        empty: {link: '', text: '', date: '', edit: true, media: ''},
        validate: {
          link: {func: (o) => {return !o.link || !this.$func.isURLValid(o.link)}, error: 'Неверный формат ссылки'},
          text: {},
        }
      }
    }
  }

</script>

<style>
  textarea {
    min-width: 380px;
  }
</style>
