<template>

  <main class="">
    <div class="form-registration">
      <div class="breadcrumb">
        <a :href="$store.getters.current_domain">Главная</a> <span>Регистрация</span>
      </div>
      <div class="row">
        <form @submit.prevent="registration" class="col-sm">
          <h1 class="h3 mb-3 fw-normal">Регистрация</h1>
          <transition name="fade">
            <div class="card" style="height: 18rem" v-if="reg_end">

              <div class="card-body">
                <h4 class="card-text" style="text-align: center">
                  Вы успешно зарегистрировались на сайте. <br>
                  Пожалуйста подтвердите свою регистрацию перейдя по
                  ссылке, которая Вам была отправлена в письме на указанный Вами E-Mail.
                </h4>
              </div>

            </div>
          </transition>
            <template v-if="!reg_end">
              <div :class="'form-floating ' + ($v.name.$error ?'form-group--error':name.length?'form-group--success':'')">
                <input class="form-control" v-model.trim="$v.name.$model" placeholder="ФИО">
                <label>ФИО</label>
                <span class="bi-place">
            <popper trigger="click" :options="{ placement: 'right',modifiers: { offset: { offset: '0,10px' } } }">
                <div class="popper text-left">
                  Введите Фамилию и Имя используя заглавные и строчные буквы  русского алфавита
                </div>
                <span slot="reference">
                   <i :class="`bi bi-info-circle`"></i>
                </span>
            </popper>
          </span>
              </div>
              <div class="form-group__message">
              <div class="error" v-if="!$v.name.required && $v.name.$error">- Введите имя</div>
              <div class="error" v-if="!$v.name.mustBeCyrillic && $v.name.$error && name.length">
                - Имя должно содержать только заглавные и строчные буквы русского алфавита
              </div>
              </div>

              <div :class="'form-floating mt-2 ' + ($v.username.$error ?'form-group--error':username.length?'form-group--success':'')">
                <input class="form-control" v-model.trim="$v.username.$model" type="email" placeholder="Email">
                <label>Email</label>
                <span class="bi-place">
            <popper trigger="click" :options="{ placement: 'right',modifiers: { offset: { offset: '0,10px' } } }">
                <div class="popper text-left">
                 Введите Ваш email используя заглавные и строчные буквы латинского алфавита и служебные символы @._-
                </div>
                <span slot="reference">
                   <i :class="`bi bi-info-circle`"></i>
                </span>
            </popper>
          </span>
              </div>
              <div class="form-group__message">
              <div class="error" v-if="!$v.username.required && $v.username.$error">- Введите Email</div>
              <div class="error" v-if="!$v.username.mustBeEmail && $v.username.$error && username.length">
                - Неверный формат email адреса
              </div>
              </div>

              <div
                :class="'form-floating mt-2 ' + ($v.organization.$error?'form-group--error':organization.length?'form-group--success':'')">
                <input class="form-control" type="text" v-model.trim="$v.organization.$model"
                       placeholder="Организация">
                <label>Организация</label>
                <span class="bi-place">
            <popper trigger="click" :options="{ placement: 'right',modifiers: { offset: { offset: '0,10px' } } }">
                <div class="popper text-left">
                Введите юридическое наименование компании из договора
                </div>
                <span slot="reference">
                   <i :class="`bi bi-info-circle`"></i>
                </span>
            </popper>
          </span>
              </div>
              <div class="form-group__message">
                <div class="error" v-if="!$v.organization.required && $v.organization.$error">
                  - Введите наименование организации
                </div>
              </div>

              <div :class="'form-floating mt-2 ' + ($v.password.$error?'form-group--error':password.length?'form-group--success':'')">
                <input class="form-control" :type="show_pass?'text':'password'" v-model.trim="$v.password.$model"
                       placeholder="Пароль">
                <label>Пароль</label>
                <i v-on:click="show_pass=!show_pass" style="right: 40px"
                   :class="`bi bi-eye${show_pass?' active':''}`"></i>
                <span class="bi-place">
            <popper trigger="click" :options="{ placement: 'right',modifiers: { offset: { offset: '0,10px' } } }">
                <div class="popper text-left">
                 Введите пароль используя буквы латинского алфавита A-Z, a-z, цифры 0-9 и служебные символы !"№;
                </div>
                <span slot="reference">
                   <i :class="`bi bi-info-circle`"></i>
                </span>
            </popper>
          </span>
              </div>
              <div class="form-group__message">
              <div class="error" v-if="!$v.password.required && $v.password.$error">- Введите пароль</div>
              <div class="error" v-if="!$v.password.minLength && $v.password.$error">
                - Пароль должен состоять минимум из {{$v.password.$params.minLength.min}} символов
              </div>
                <div class="error" v-if="!$v.password.truePassword && $v.password.$error">
                  - Пароль должен содержать заглавные и строчные буквы, цифры и специальные символы
                </div>
              </div>


              <div :class="'form-floating mt-2 ' + ($v.repeat_password.$error?'form-group--error':repeat_password.length?'form-group--success':'')">
                <input class="form-control" :type="show_pass?'text':'password'" v-model.trim="$v.repeat_password.$model"
                       placeholder="Повторите пароль">
                <label>Повторите пароль</label>
                <i v-on:click="show_pass=!show_pass" style="right: 40px"
                   :class="`bi bi-eye${show_pass?' active':''}`"></i>
              </div>
              <div class="form-group__message">
              <div class="error" v-if="!$v.repeat_password.required && $v.repeat_password.$error">- Введите пароль</div>
              <div class="error"
                   v-if="!$v.repeat_password.sameAsPassword && $v.repeat_password.$error && repeat_password.length">
                - Введённые пароли не совпадают
              </div>
              </div>


              <div :class="'checkbox mt-2 ' + ($v.agree.$error?'form-group--error':agree.length?'form-group--success':'')">
                <label>
                  <input type="checkbox" v-model.trim="$v.agree.$model" value="remember-me">
                  Согласен с <a target="_blank" :href="agree_link">условиями обработки персональных данных</a>
                </label>
              </div>
              <div class="error" v-if="!$v.agree.required && $v.agree.$error">
                - Необходимо согласие на обработку персональных данных
              </div>


              <button class="w-100 btn btn-lg btn-primary mt-3" type="submit">Зарегистрироваться</button>
            </template>
        </form>
        <div class="mt-3">
          <router-link to="/forgot_password/">Забыли пароль?</router-link>
          |
          <router-link to="/login/">Авторизация</router-link>
        </div>
      </div>
    </div>
  </main>

</template>
<script>
  import {required, minLength, sameAs} from 'vuelidate/lib/validators'
  import Popper from 'vue-popperjs';
  import 'vue-popperjs/dist/vue-popper.css';
  import {UserApi} from '@/api/user'

  const mustBeEmail = (value) => /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(value);
  const mustBeCyrillic = (value) => /^[\u0400-\u04FF, ]+$/.test(value);
   const truePassword = (value) => /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\W\w])[A-Za-z\d\W\w]{8,}$/.test(value);
  export default {

    name: 'UserRegistration',
    data() {
      return {
        name: "",
        password: "",
        repeat_password: "",
        username: "",
        organization: "",
        agree: "",
        show_pass: false,
        reg_end: false,
      }
    },
    computed: {
      agree_link(){
        return location.origin.includes("orion")?"/user-agreement":"https://ton-reputation.com/user-agreement/"
      }
    },
    components: {
      'popper': Popper
    },
    validations: {
      organization: {
        required,
      },
      agree: {
        required,
      },
      username: {
        required,
        mustBeEmail,
      },
      name: {
        required,
        mustBeCyrillic
      },
      password: {
        required,
        truePassword,
        minLength: minLength(8)
      },
      repeat_password: {
        required,
        sameAsPassword: sameAs('password')
      },
    },
    mounted: function () {

    },
    methods: {
      registration: function (e) {
        e.preventDefault();
        let $this = this,
          user = {username: this.username, name: this.name, password: this.password, organization: this.organization};
        this.$v.$touch();
        if (!this.$v.$invalid) {
          UserApi.registration(user).then(response => {
            if (response.data['errors']) {
              for (let i in response.data['errors']) {
                $this.$notify({
                  group: 'alert',
                  type: 'error ',
                  text: response.data['errors'][i]
                })
              }
            }
            else $this.reg_end = true;

          });

        }
      }
    }
  }
</script>
<style scoped>


  .form-registration {
    width: 100%;
    padding: 15px;
    max-width: 700px;
    margin: auto;
  }

  .form-registration .checkbox {
    font-weight: 400;
  }

  .form-registration .form-floating:focus-within {
    z-index: 2;
  }

  .form-registration input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .form-registration input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }


</style>
