<template>
  <div>
    <form v-on:submit.prevent="submitReport" class="rounded border border-primary p-3">
      <div class="row mb-3 align-items-center">
        <h5><i class="bi bi-file-earmark-arrow-down"></i>Отчёты</h5>
        <div class="col">
          <select required v-model="report.report_type" id="id_report" class="form-select">
            <option v-for="(type_name, type) in report_types" :value="type" :key="type">{{ type_name.name }}</option>
          </select>
        </div>
      </div>
      <div class="row mb-3 align-items-center" v-if="report_types[report.report_type]">
        <div>


          <div class="d-flex justify-content-start align-items-center">
            <template v-if="report_types[report.report_type].settings.date">
              <span class="me-2 col-2">с</span>
              <date-picker readonly id="id_date" class="me-1 col" v-model="report.date_start"
                           ref="date_start"
                           :config="datepicker_option"></date-picker>
            </template>
            <span class="me-2 col-2" v-if="!report_types[report.report_type].settings.date">на</span>
            <span class="me-2" v-else>по</span>
            <date-picker readonly v-model="report.date" ref="date" class="col"
                         :config="datepicker_option"></date-picker>
          </div>
        </div>
        <div>
        <div v-if="report_types[report.report_type].settings.template"
           :class="'row mb-3 mt-3 align-items-center'+ (report.template_error?' form-group--error':'')">
        <div class="col-2">
          <label for="id_state">Шаблон</label>
        </div>
        <div class="col">
          <select v-model="report.template" id="id_template" class="form-select">
            <option v-for="(name, val) in template_options" :value="val">{{ name }}</option>
          </select>
          <div class="error" v-if="report.template_error">{{ report.template_error }}</div>
        </div>
      </div>
      </div>
      </div>

      <button class="btn btn-success" type="submit">Сформировать отчёт</button>
    </form>

    <template v-if="request.id && !request.report_file ">
      Ожидание результата ...
    </template>
    <template v-if="request.report_file">
      <a :href="request.report_file"><i class="bi bi-file-arrow-down"></i> Скачать отчёт</a><br>

    </template>
    <template v-else-if="request.error">
      <span class="text-danger"><i class="bi bi-exclamation-lg"></i> Произошла ошибка</span>
    </template>
  </div>
</template>

<script>
import datePicker from "vue-bootstrap-datetimepicker";
import {ReportApi} from "@/api/reports";
import moment from 'moment-timezone'

export default {
  name: "SmallReport",
  props: ['project_id', 'date_start', 'date', 'date_config', 'state', 'country_id'],
  data() {
    return {
      template_options: {
        dr: 'тональность - цвет ссылок',
        dr_bg: 'тональность - фон',
      },
      report_types: {
        top_search_results_report: {
          name: "Отчёт выдачи PPTX",
          settings: {
            date: false,
            state: true,
            country: false,
            template: true
          }
        },
        user_search_result_report: {
          name: "Отчёт выдачи XLSX",
          settings: {
            date: false,
            state: true,
            country: false,
            template: false
          }
        },
        client: {
          name: "Информация о проекте XLSX",
          settings: {
            date: false,
            state: true,
            country: false,
            template: false
          }
        }
      },
      request: {id: null, path: '', error: false},
      timerId: null,
      report: {}
    }
  },
  components: {
    datePicker
  },

  watch: {
    project_id() {
      this.setReport()
    },
    state() {
      this.setReport()
    },
    country_id() {
      this.setReport()
    },
    report: {
      handler(val) {
        if (this.report_types[val.report_type] && !this.report_types[val.report_type].settings.date && val.date) {
          this.report.date_start = val.date;
        }
      },
      deep: true
    }
  },
  computed: {
    datepicker_option() {
      if (this.date_config) return this.date_config
      return this.$DATEPICKER_OPTION
    }
  },
  mounted() {
    this.setReport()
  },
  methods: {
    setReport() {
      this.report = {
        report_type: {}, 'state': this.state,
        date: moment(this.date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        date_start: moment(this.date_start, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        project: this.project_id,
        additional: {countries: [this.country_id]},
        template: 'dr'
      }
    },
    submitReport() {
      let $this = this;
      ReportApi.create_report(this.report).then(response => {
        $this.request.id = response.id;
        $this.timerId = setInterval(() => {
          $this.getReport($this.request.id)
        }, 10000);
        $this.$notify({
          group: 'alert',
          type: 'success ',
          text: 'Отчёт отправлен на формирование, результат так же можно получить в разделе "Отчёты"'
        });
      }).catch(err => {
        this.report.project = this.project;
        let e = err.response.data;
        typeof (e) == 'object' && e.non_field_errors !== undefined && (e = e.non_field_errors.toString());
        this.$notify({
          group: 'alert',
          type: 'error ',
          text: e
        });
      });
    },
    getReport(id) {
      const $this = this;
      ReportApi.get(id).then(resp => {
        $this.request = {...$this.request, ...resp}
        if ($this.request.error || $this.request.report_file) {
          clearInterval($this.timerId)
          $this.$notify({
            group: 'alert',
            type: 'success ',
            text: `Отчёт сформирован`
          });
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
