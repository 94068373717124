<template>
  <main class="dashboard">
        <div class="d-flex pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">{{current_page.name}}</h1>
    </div>
    <div class="text" v-html="current_page.description"></div>

    <div class="card">
      <div class="card-body">

        <div class="table-responsive">
          <table class="table dict">
            <thead>
            <tr>
              <th scope="col"> №</th>
              <th scope="col" class="mw200">Соц сеть</th>
              <th scope="col" class="mw200">Возраст</th>
              <th scope="col" class="mw200">Пол</th>
              <th scope="col" class="mw200">Тематика</th>
              <th scope="col" class="mw200">Регион</th>
              <th scope="col" class="mw200">Возраст аккаунта гг</th>
              <th scope="col" class="mw200">Количество друзей</th>
              <th scope="col" class="mw200">Количество постов в мес</th>
              <th scope="col" class="mw200">Количество профилей</th>
              <th scope="col">Дата добавления / изменения</th>
              <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            <tr class="empty"></tr>
            <template v-for="(object, index) in objects">
              <tr :class="object.edit?'edit':''">
                <td>{{(index + 1) + ((page - 1) * size)}}</td>
                <template v-if="object.edit">
                  <td>
                    <div :class="object.social_error?'form-group--error':''">
                      <label class="d-none">Соц сеть</label>
                      <select v-model="object.social" class="form-select">
                        <option v-for="social in dicts.social" :value="social.id">{{social.name}}</option>
                      </select>
                    </div>
                    <div class="error" v-if="object.social_error">{{object.social_error}}</div>
                  </td>
                  <td>
                    <div :class="object.age_error?'form-group--error':''">
                      <label class="d-none">Возраст</label>
                      <select v-model="object.age" class="form-select">
                        <option v-for="(value, key) in select.age" :value="key">{{value}}</option>
                      </select>
                    </div>
                    <div class="error" v-if="object.age_error">{{object.age_error}}</div>
                  </td>
                  <td>
                    <div :class="object.gender_error?'form-group--error':''">
                      <label class="d-none">Пол</label>
                      <select v-model="object.gender" class="form-select" v-on:change="object.theme=''">
                        <option v-for="(value, key) in select.gender" :value="key" :key="key">{{value}}</option>
                      </select>
                    </div>
                    <div class="error" v-if="object.gender_error">{{object.gender_error}}</div>
                  </td>
                  <td>
                    <div :class="object.theme_error?'form-group--error':''">
                      <label class="d-none">Тематика</label>
                      <select v-model="object.theme" class="form-select">
                        <option v-for="theme in dicts.theme.filter(x => x.gender === object.gender)" :value="theme.id">
                          {{theme.name}}
                        </option>
                      </select>
                    </div>
                    <div class="error" v-if="object.theme_error">{{object.theme_error}}</div>
                  </td>
                  <td>
                    <div :class="object.region_error?'form-group--error':''">
                      <label class="d-none">Регион</label>
                      <select v-model="object.region" class="form-select">
                        <option v-for="region in dicts.region" :value="region.id">{{region.name}}</option>
                      </select>
                    </div>
                    <div class="error" v-if="object.region_error">{{object.region_error}}</div>
                  </td>
                  <td>
                    <div :class="object.account_age_error?'form-group--error':''">
                      <label class="d-none">Возраст аккаунта гг</label>
                      <select v-model="object.account_age" class="form-select">
                        <option v-for="(value, key) in select.account_age" :value="key">{{value}}</option>
                      </select>
                    </div>
                    <div class="error" v-if="object.account_age_error">{{object.account_age_error}}</div>
                  </td>
                  <td>
                    <div :class="object.friends_count_error?'form-group--error':''">
                      <label class="d-none">Количество друзей</label>
                      <select v-model="object.friends_count" class="form-select">
                        <option v-for="(value, key) in select.friends_count" :value="key">{{value}}</option>
                      </select>
                    </div>
                    <div class="error" v-if="object.friends_count_error">{{object.friends_count_error}}</div>
                  </td>
                  <td>
                    <div :class="object.post_count_error?'form-group--error':''">
                      <label class="d-none">Количество постов в мес</label>
                      <select v-model="object.post_count" class="form-select">
                        <option v-for="(value, key) in select.post_count" :value="key">{{value}}</option>
                      </select>
                    </div>
                    <div class="error" v-if="object.post_count_error">{{object.post_count_error}}</div>
                  </td>

                  <td>
                    <div :class="object.profile_count_error?'form-group--error':''">
                      <label class="d-none">Количество профилей</label>
                      <input type="number" min="1" step="1"
                             onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                             v-model="object.profile_count" class="form-control"/>
                    </div>
                    <div class="error" v-if="object.profile_count_error">{{object.profile_count_error}}</div>
                  </td>

                </template>
                <template v-else>
                  <td>{{object.social_name}}</td>
                  <td>{{object.get_age_display}}</td>
                  <td>{{object.get_gender_display}}</td>
                  <td>{{object.theme_name}}</td>
                  <td>{{object.region_name}}</td>
                  <td>{{object.get_account_age_display}}</td>
                  <td>{{object.get_friends_count_display}}</td>
                  <td>{{object.get_post_count_display}}</td>
                  <td>{{object.profile_count}}</td>
                </template>

                <td>{{object.date}}</td>
                <td>
                  <a href="" v-if="!object.edit" v-on:click.prevent="$func.setValue(object, 'edit', true);"
                     class="no-wrap"><i class="bi bi-pencil"></i>
                    <small>редактировать</small>
                  </a>
                  <template v-else>
                    <a href="" class="text-success ms-sm-2 no-wrap" v-on:click.prevent="saveNote(object)"><i
                      class="bi bi-check-circle"></i>
                      <small>Сохранить</small>
                    </a> <br>
                    <a href="" class="text-danger ms-sm-2 no-wrap" v-on:click.prevent="endEdit(object)"><i
                      class="bi bi-x-circle"></i>
                      <small>Отмена</small>
                    </a>
                  </template>
                </td>

              </tr>
            </template>
            <tr v-if="!objects.length">
              <td colspan="12">
                Не добавлено ни одной записи
              </td>
            </tr>
            <tr v-if="objects.length < max_length">
              <td colspan="12">
                <button class="btn btn-success" v-on:click="addNote" title="Добавить запись">+</button>
              </td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
     <PagerList v-bind:pager="pager" v-bind:updatePage="updatePage" v-bind:page="page" ></PagerList>


    </div>


  </main>
</template>

<script>
  import dataMixin from './mixins.js';
  export default {
    name: 'InfluenceService',
    mixins: [dataMixin],
    data() {
      return {
        name: 'agents',
        empty: {
          social: '', age: '', date: '', edit: true, gender: '', theme: '', region: '', account_age: '',
          friends_count: '', post_count: '', profile_count: ''
        },
        validate: {
          'social': {}, 'region': {}, 'age': {}, 'account_age': {}, theme:{}, gender: {}
        },
        select: {
          age: [], gender: [], account_age: [], friends_count: [], post_count: []
        },
        dicts: {
          social: [],
          region: [],
          theme: [],
        },

      }
    }
  }

</script>


<style>
  textarea {
    min-width: 380px;
  }

  .form-select {
    min-width: 150px;
  }
</style>
