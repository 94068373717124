<template>
  <main class="dashboard">
    <div class="card">
      <div class="card-body text-center m-5">
        <img width="200" :src="`${$media_url}images/service.png`"/>
        <h3>Раздел находится в разработке</h3>
      </div>
    </div>
    <!--<div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">Влияние: {{object.name}}</h1>
      <div class="btn-toolbar mb-2 mb-md-0">
        <div class="btn-group me-2">

        </div>
        <div class="btn-toolbar mb-2 mb-md-0">
          <div class="btn-group me-2">
            <button v-on:click.prevent="addNote" class="btn btn-sm blue-bg btn-primary">Добавить запись <i
              class="bi bi-plus-circle ms-1"></i></button>
          </div>
        </div>
      </div>
    </div>

    <div class="card">


      <div class="card-body">
        <template v-if="!contents.length && !content">Не добавлено ни одной записи</template>
        <div class="table-responsive" v-if="!edit && contents.length">
          <table class="table">
            <thead>
            <tr>
              <th scope="col" class="sticky-top"> Заголовок</th>
              <th scope="col" class="sticky-top">Статус</th>
              <th scope="col" class="sticky-top">Дата</th>
              <th scope="col" class="sticky-top">Действия</th>
            </tr>
            </thead>
            <tbody>
            <tr class="empty"></tr>
            <template v-for="link in contents">
              <tr>
                <td><a href="" v-on:click.prevent="getNote(link.id); $modal.show('content');">{{link.name}}</a></td>
                <td>{{link.get_status_display}}</td>
                <td>{{link.date_create}}</td>
                <td>
                  <template v-if="link.status == 'draft'">
                    <a href="" class="no-wrap" v-on:click.prevent="getNote(link.id); edit=true;"><i class="bi bi-pencil"></i>
                      <small>редактировать</small>
                    </a>
                    <a href="" class="text-danger ms-sm-2" v-if="link.status == 'draft'"
                       v-on:click.prevent="deleteNote(link);"><i class="bi bi-x-circle"></i>
                      <small>Удалить</small>
                    </a>
                  </template>
                </td>

              </tr>
            </template>
            </tbody>
          </table>

        </div>

        <template v-if="edit">

          <div
            :class="'mt-2 ' + ($v.content.name.$error ?'form-group--error':content.name.length?'form-group--success':'')">
            <label class="form-label">Заголовок</label>
            <input class="form-control" v-model.trim="$v.content.name.$model" placeholder="Заголовок">
          </div>
          <div class="error" v-if="!$v.content.name.required && $v.content.name.$error">- Введите заголовок</div>

          <div
            :class="'mt-3 ' + ($v.content.text.$error ?'form-group--error':content.text.length?'form-group--success':'')">
            <label for="comment" class="form-label">Текст статьи</label>
            <vue-editor v-model.trim="$v.content.text.$model"/>
          </div>
          <div class="error" v-if="!$v.content.text.required && $v.content.text.$error">- Введите Текст</div>

          <div class="mb-3 mt-3">
            <label for="comment" class="form-label">Комментарий</label>
            <textarea class="form-control" id="comment" v-model="content.comment" rows="3"></textarea>
          </div>
          <template v-if="content.status=='draft'">
            <button class="btn mt-1 btn-sm btn-success" v-on:click.prevent="saveNote()">Сохранить</button>
            <button class="btn mt-1 btn-sm btn-primary" v-on:click.prevent="$modal.show('pre_save');">
              Отправить на обработку
            </button>
          </template>
          <button class="btn mt-1 btn-sm btn-secondary" v-on:click.prevent="edit=false;content=null;">Вернуться к списку
          </button>
        </template>


      </div>

      <nav>
        <ul class="pagination justify-content-center" v-if="pager.length>1">
          <li :class="`page-item${p==page?' active':''}`" v-for="p in pager">
            <a class="page-link" v-on:click.prevent="page=p" href="">{{p}}</a>
          </li>
        </ul>
      </nav>


    </div>
    <modal name="content" class="influence_modal" :scrollable="true" :width="'70%'" :height="'auto'" style="margin-top: 50px">
      <div class="modal-content" v-if="content">
        <div class="modal-header">
          <button type="button" class="btn-close" @click="content=null;$modal.hide('content');"
                  data-bs-dismiss="modal"
                  aria-label="Close"></button>
        </div>
        <div class="modal-body flex">
          <div class="card">

            <div class="card-body" v-if="contents.length">
              <article class="blog-post">
                <h2 class="blog-post-title">{{content.name}}</h2>
                <p class="blog-post-meta">{{content.date_create}}</p>
                <div v-html="content.text"></div>
                <hr>
                <p>
                  <small>Комментарий: </small>
                  <br>
                  {{content.comment}}
                </p>
              </article>


            </div>


          </div>
        </div>

      </div>
    </modal>
    <modal name="pre_save" class="modal-dialog" :adaptive="true" :height="'auto'">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn-close" @click="$modal.hide('pre_save');" data-bs-dismiss="modal"
                  aria-label="Close"></button>
        </div>
        <div class="modal-body flex">
          <h5>После отправки, текст станет не доступен для редактирования</h5>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success"
                  v-on:click="content.status='manager';saveNote();$modal.hide('pre_save');">Отправить
          </button>
          <button type="button" class="btn btn-danger" @click="$modal.hide('pre_save');"
                  data-bs-dismiss="modal">Отмена
          </button>

        </div>
      </div>
    </modal>-->
  </main>
</template>

<script>
  import {ProjectApi} from '../../api/project'
  import {InfluenceApi} from '../../api/influence'
  import {required} from 'vuelidate/lib/validators'

  export default {
    name: 'ProjectInfluences',
    components: {},

    data() {
      return {
        size: 20,
        all_count: 0,
        page: 1,
        contents: [],
        edit: false,
        object: {},
        content: null,

      }
    },
    validations: {
      content: {
        name: {
          required
        },
        text: {
          required
        }
      },
    },
    computed: {
      pager() {
        let page_count = Math.ceil(this.all_count / this.size);
        return Array.from({length: page_count}, (_, i) => i + 1);
      },

    },
    watch: {
      $route() {
        this.edit = false;
        this.content = null;
        this.getData()
      },
      page: {
        deep: true,
        handler() {
          this.getData();
        }

      }
    },
    mounted: function () {
      this.getData();
    },
    methods: {
      getData: function () {
        let $this = this;
        InfluenceApi.list($this.$route.params.id, $this.page, $this.size).then(response => {
          $this.all_count = response.count;
          $this.contents = response.results;
        });
        ProjectApi.get($this.$route.params.id).then(response => {
          $this.object = response;

        });
      },
      addNote: function () {
        this.content = {name: '', project: this.$route.params.id, text: '', comment: '', status: 'draft'};
        this.edit = true;
      },
      getNote: function (id) {
        InfluenceApi.get(id).then(response => {
          this.content = response;
        });
      },
      deleteNote: function (content) {
        let $this = this;
        InfluenceApi.delete_object(content.id).then(() => {
          $this.contents.splice($this.contents.indexOf(content), 1);
        });
      },
      saveNote: function () {
        let $this = this;
        this.$v.$touch();
        if (!this.$v.$invalid) {
          if (!$this.content.text) {
            this.$notify({
              group: 'alert',
              type: 'error ',
              text: 'Заполните текст статьи'
            });
          }
          else {
            InfluenceApi.update($this.content).then(response => {
              $this.content = response;
              $this.getData();
            });
            this.$notify({
              group: 'alert',
              type: 'success ',
              text: 'Запись сохранена'
            });
          }
        }
      },
    },

  }

</script>

<style scoped="">
  .card {
    z-index: 1;
  }
</style>

