import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store/index'
import HTTP from './api/common'
import vmodal from 'vue-js-modal'
import Notifications from 'vue-notification'
import "../static/css/style.css"
import Vuelidate from 'vuelidate'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import "bootstrap/dist/css/bootstrap.min.css"
import 'bootstrap-icons/font/bootstrap-icons.css'
import "bootstrap"
import BackButton from '@/components/BackButton'
import Vue2Editor from "vue2-editor";
// import './registerServiceWorker'


Vue.prototype.$TONES = {
    'negative': {
        name: 'Нец.', type_tones: {
            'new': 'Новый', 'offer': 'Договор',
        },
        synonym: ['negative'],
        no_filter: false
    },
    'not_negative': {
        name: 'Нейтрал.', type_tones: {
            'new': 'Новый'
        },
        synonym: ['not_negative', 'irrelevant'],
        no_filter: true
    },
    'irrelevant': {
        name: 'Нейтрал.', type_tones: {
            'new': 'Новый', 'start': 'На старте'
        },
        synonym: ['not_negative', 'irrelevant'],
        no_filter: false
    },
    'positive': {
        name: 'Цель.', type_tones: {
            'new': 'Новый', 'our': 'Наш', 'start': 'На старте',
            'finish': 'Добивочные',
            'replace': 'Корректировка',
        },
        synonym: ['positive'],
        no_filter: false
    }
};
Vue.prototype.$DATEPICKER_OPTION = {
    format: 'YYYY-MM-DD',
    locale: 'ru',
    useCurrent: true,
    ignoreReadonly: true
};

Vue.config.productionTip = false;
Vue.prototype.$http = HTTP;
Vue.prototype.$media_url = process.env.ROOT_MEDIA;
Vue.prototype.$STATES = [
            {
                id: 10,
                name: 'Топ-10'
            },
            {
                id: 20,
                name: 'Топ-20'
            },
            {
                id: 50,
                name: 'Топ-50'
            },
            {
                id: 100,
                name: 'Топ-100'
            }
        ]
Vue.prototype.$COLORS = {
    'negative_auto_new': '#ec6b0a',
    'negative': '#d50004',
    'negative_manual_offer': '#d50004',
    'negative_manual_new': '#ff003a',
    'not_negative': '#808080',
    'not_negative_auto_new': '#808080',
    'not_negative_manual_start': '#808080',
    'not_negative_manual_new': '#808080',
    'positive_manual_finish': '#dbd116',
    'positive_manual_replace': '#8729a6',
    'positive_manual_start': '#1974d2',
    'positive_manual_new': '#1974d2',
    'positive': '#228b22',
    'positive_manual_our': '#228b22',
    'irrelevant': '#808080',
    'irrelevant_manual_start': '#808080',
    'irrelevant_manual_new': '#808080',
};

Vue.prototype.$func = {
    historyBack: (path) => {
        if (!document.referrer || document.referrer === window.location.href) router.push(path);
        else router.go(-1)
    },
    getClass: function (link, calc = false) {
        let COLORS = Vue.prototype.$COLORS;
        let tone = link.tone;
        if (calc) tone = `${link.tone}_${link.kind_type}_${link.type_tone}`;
        return `color: ${COLORS[tone] !== undefined ? COLORS[tone] : '#000000'}`;
    },
    momentDate: (date, format = "YYYY-MM-DD", default_type = false) => {
        try {
            if (default_type) return moment(date, default_type).format(format)
            return moment(date).format(format)
        } catch (e) {
            return date
        }
    },
    getTones: function () {
        return Vue.prototype.$TONES;
    },
    getTypes: function (tone) {

        return Vue.prototype.$TONES[tone] ? Vue.prototype.$TONES[tone].type_tones : {};
    },
    setValue: function (obj, name, val) {
        Vue.set(obj, name, val)
    },
    isURLValid: function () {
        // let regex = new RegExp('^(https?:\\/\\/)?'+ // protocol
        // '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        // '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        // '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        // '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        // '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        // return regex.test(inputUrl)
        return true;
    },
    isURL(url){
        try {
            return new URL(url)
        } catch(err){
            return false
        }
    },
    getPagerArray: function (all_count, size, page) {
        let page_count = Math.ceil(all_count / size);
        let pages = Array.from({length: page_count}, (_, i) => i + 1);
        let array = [];
        if (page > 5) {
            array = array.concat(pages.slice(0, page - 3 > 3 ? 3 : page - 3));
            array.push('...')
        }
        array = array.concat(pages.slice(page - 4 > 0 ? page - 4 : 0, page + 3));
        if (page < (pages.length - 5)) {
            array.push('...');
            array = array.concat(pages.slice(pages.length - 3, pages.length));
        }
        return array;
    },
};

Vue.directive('focus', {
    inserted: function (el) {
        Vue.nextTick(function () {
            el.focus()
        })
    }
})

Vue.use(vmodal, {dialog: true, dynamic: true, dynamicDefaults: {clickToClose: false}});
Vue.use(Notifications);
Vue.component('BackButton', BackButton)
Vue.use(Vuelidate);
Vue.use(Vue2Editor);

if(window.navigator && navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations()
  .then(function(registrations) {
    for(let registration of registrations) {
      registration.unregister();
    }
  });
}

require('moment/locale/ru');
Vue.use(VueMoment, {
    moment,
});

new Vue({
    el: '#app',
    store,
    router,
    components: {App},
    template: '<App/>'
});
