import { HTTP } from './common'

export const InfluenceApi = {
  list (id, page, size) {
    return HTTP.get(`user/influence/?project=${id}&page=${page}&size=${size}`).then(response => {
      return response.data
    })
  },
  get(id) {
    return HTTP.get(`user/influence/${id}/`).then(response => {
      return response.data
    })
  },
  update (object) {
    if (object.id) return HTTP.put(`user/influence/${object.id}/`, object).then(response => {
      return response.data
    });
    else return HTTP.post('user/influence/', object).then(response => {
      return response.data
    })
  },
  delete_object (id) {
    return HTTP.delete(`user/influence/${id}/`).then(response => {
      return response.data
    });
  },

};

