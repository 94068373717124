import {DictionaryApi} from '@/api/dictionary'
import PagerList from '@/components/includes/PagerList.vue';
import datePicker from 'vue-bootstrap-datetimepicker';
import ResponseChart from "@/components/dictionary/includes/ResponseChart.vue"

let dataMixin = {
    data() {
        return {
            objects: [],
            max_length: 100,
            size: 20,
            all_count: 0,
            page: 1,
            name: '',
            empty: {},
            validate: {},
            select: {},
            dicts: {},
            filters: {},
            date_options: {
                format: 'YYYY-MM-DD',
                locale: 'ru',
                useCurrent: true,
                enabledDates: [],
                ignoreReadonly: true
            },
        }
    },
    components: {
        PagerList, datePicker, ResponseChart
    },
    computed: {
        pager() {
            return this.$func.getPagerArray(this.all_count, this.size, this.page)
        },
        current_page() {
            let pages = this.$store.getters.pages.filter((page) => {
                return page.slug === this.$route.name
            });
            return pages.length ? pages[0] : {}
        },

    },
    watch: {
        $route() {
            this.getData()
        },
        page: {
            deep: true,
            handler() {
                this.getData();
            }

        },
    },
    mounted: function () {
        let $this = this;
        this.getData();
        if (Object.keys(this.select).length || Object.keys(this.dicts).length) {
            DictionaryApi.get_dict(this.name, Object.keys(this.dicts).join(','), Object.keys(this.select).join(',')).then((resp) => {
                for (let dt in $this.dicts) {
                    $this.dicts[dt] = resp[dt]
                }
                for (let sel in $this.select) {
                    $this.select[sel] = resp[sel]
                }
            });
        }
    },
    methods: {
        addNote: function () {
            this.objects.push(JSON.parse(JSON.stringify(this.empty)));
        },
        getData() {
            let $this = this;
            let query = Object.entries(this.filters).map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`).join('&');
            DictionaryApi.get_list(this.name, $this.page, $this.size, query).then(response => {
                $this.objects = response.results;
                $this.all_count = response.count;
            });
        },
        endEdit(object) {
            let $this = this;
            if (object.id) {
                DictionaryApi.get(this.name, object.id).then(response => {
                    $this.objects.splice($this.objects.indexOf(object), 1, response);
                });
            } else this.objects.splice(this.objects.indexOf(object), 1);
            if (this.edit_object) this.edit_object = null;
        },
        setFile(field, index, object) {
            if (this.$refs[`${field}-${index}`][0].files) {
                this.objects[this.objects.indexOf(object)][field] = this.$refs[`${field}-${index}`][0].files[0].name;
            }
        },
        validateData: function (object) {
            let error = false;
            for (let field in this.validate) {
                let rule = this.validate[field];
                if (!rule.func) {
                    if (!object[field]) {
                        error = true;
                        this.$func.setValue(object, `${field}_error`, rule.error ? rule.error : 'Обязательное поле')
                    }
                } else {
                    if (rule.func(object)) {
                        error = true;
                        this.$func.setValue(object, `${field}_error`, rule.error ? rule.error : 'Обязательное поле')
                    } else
                        this.$func.setValue(object, `${field}_error`, false)
                }
            }
            return error;
        },
        saveNote: function (object) {
            let errors = this.validateData(object);
            if (!errors) {
                this.submitNote(object)
            }
        },
        submitNote(object, alert = true) {
            let $this = this, formData = new FormData();
            if ((Object.keys(object).indexOf('report') + 1) > 0) {
                for (let i in object) {
                    if (i === 'report' && (!object.report || typeof (object.report) === 'string')) formData.set(i, "")
                    else formData.set(i, object[i])
                }
            } else formData = object;
            DictionaryApi.save(this.name, formData, object.id).then(response => {

                $this.objects.splice($this.objects.indexOf(object), 1, response);
                if ($this.edit_object) $this.edit_object = null;
                if (alert) $this.$notify({
                    group: 'alert',
                    type: 'success ',
                    text: 'Запись сохранена'
                });
            }).catch((err) => $this.$notify({
                    group: 'alert',
                    type: 'error',
                    text: err.response.data.error
                })
            );

        },
        clickDate(event) {

            let position = event.target.getBoundingClientRect();
            let position_parent = document.getElementsByClassName('table dict')[0].getBoundingClientRect();
            let cal = document.getElementsByClassName('bootstrap-datetimepicker-widget')[0];
            cal.setAttribute('style', `top: ${parseInt(position.y - position_parent.y + 50)}px; left: ${parseInt(position.x - position_parent.x)}px;`)
        },
        updatePage(page) {
            this.page = page
        }
    }
};
export default dataMixin;
