<template>

  <main class="text-center">
    <div class="form-signin">
      <transition name="fade">
            <div class="card" style="height: 18rem" v-if="reg_end">

              <div class="card-body">
                <h4 class="card-text" style="position: absolute;top: 25%; text-align: center">
                  Инструкции по восстановлению пароля отправлены на указанный email.
                </h4>
              </div>

            </div>
          </transition>
      <form @submit.prevent="restore_password" v-if="!reg_end">
        <h1 class="h3 mb-3 fw-normal">Восстановление пароля</h1>
        <div :class="'form-floating ' + ($v.username.$error ?'form-group--error':username.length?'form-group--success':'')">
          <input class="form-control" v-model.trim="$v.username.$model" placeholder="Email">

          <span class="bi-place">
          <popper trigger="click" :options="{ placement: 'right',modifiers: { offset: { offset: '0,10px' } } }">
                            <div class="popper text-left">
                              Введите email, на который был зарегистрирован личный кабинет

                            </div>
                            <span slot="reference">
                               <i  :class="`bi bi-info-circle`"></i>
                            </span>
                          </popper>
</span>

          <label>Email</label>
        </div>
        <div class="form-group__message">
        <div class="error" v-if="!$v.username.required && $v.username.$error">- Введите Email</div>
        <div class="error" v-if="!$v.username.mustBeEmail && $v.username.$error && username.length">
          - Неверный формат email адреса
        </div>
        </div>

        <button class="w-100 btn btn-lg btn-primary mt-3" type="submit">Восстановить</button>
      </form>
      <div class="mt-3">
        <router-link to="/login/">Авторизация</router-link>
        |
        <router-link to="/registration/">Регистрация</router-link>
      </div>
    </div>
  </main>

</template>
<script>
  import {required} from 'vuelidate/lib/validators'
  import Popper from 'vue-popperjs';
  import 'vue-popperjs/dist/vue-popper.css';
  import {UserApi} from '../../api/user'

  const mustBeEmail = (value) => /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(value);
  export default {

    name: 'ForgotPassword',
    data() {
      return {
        username: "",
        reg_end: false,
      }
    },
    components: {
      'popper': Popper
    },
    validations: {
      username: {
        required,
        mustBeEmail,
      }
    },
    mounted: function () {
      if (localStorage.getItem('username')) this.username = localStorage.getItem('username');
    },
    methods: {
      restore_password: function (e) {
         e.preventDefault();
        let $this = this;
        this.$v.$touch();
        if (!this.$v.$invalid) {
          UserApi.restore_password(this.username).then(response => {
            if (response.data['errors']) {
              for (let i in response.data['errors']) {
                $this.$notify({
                  group: 'alert',
                  type: 'error ',
                  text: response.data['errors'][i]
                })
              }
            }
            else $this.reg_end = true;

          });

        }
      }
    }
  }
</script>
<style scoped>

  main {
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #f5f5f5;
    height: 100%;
  }

  .form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
  }

  .form-signin .checkbox {
    font-weight: 400;
  }

  .form-signin .form-floating:focus-within {
    z-index: 2;
  }

  .form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }


</style>
