<template>
  <main class="dashboard">
    <div class="d-flex pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">{{ current_page.name }}</h1>
    </div>
    <div class="text" v-html="current_page.description"></div>

    <div class="card">
      <template v-if="response">
        <div
            class="card-header d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <div>
            <h3><a :href="response.link">{{ response.link }}</a></h3>
            <div class="d-flex flex-column">
                    <span class="text-secondary" v-if="response.best_place">
                      Лучшая позиция в поиске:
                      <b class="text-dark">{{ response.best_place }}</b>
                    </span>
              <span class="text-secondary" v-if="response.links_in_top20">Количество ссылок в поиске: <b
                  class="text-dark">{{ response.links_in_top20 }}</b></span>
              <span class="text-secondary">Дата: <b
                  class="text-dark">{{ response.date }}</b></span>
            </div>
          </div>

          <button class="btn" @click="clearResponse"><b class="h3"><i class="bi bi-x-lg"></i></b>
          </button>
        </div>
        <div class="card-body">
          <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" :class="`btn ${!is_positive?'btn-danger':'btn-secondary'}`"
                    @click="is_positive=false">Негативные отзывы
            </button>
            <button type="button" :class="`btn ${is_positive?'btn-success':'btn-secondary'}`" @click="is_positive=true">
              Положительные отзывы
            </button>
          </div>

          <div class="card w-75 mt-3" v-for="resp in responses" v-if="resp.content">
            <div class="card-body">
              <p class="card-text">{{ resp.content }}</p>
            </div>
          </div>
          <PagerList class="mt-3" v-bind:pager="response_pager" v-bind:updatePage="updateResponsePage"
                     v-bind:page="response_page"></PagerList>
        </div>
      </template>
      <template v-else>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table dict">
              <thead>
              <tr>
                <th scope="col"> №</th>
                <th scope="col" class="mw200"> Ссылка на сайт отзовик</th>
                <th scope="col"></th>
              </tr>
              </thead>
              <tbody>
              <tr class="empty"></tr>
              <template v-for="(object, index) in objects">
                <tr :class="object.edit?'edit':''">
                  <td class="col-1">{{ (index + 1) + ((page - 1) * size) }}</td>
                  <td style="width: 400px">
                    <a :href="object.link" target="_blank">{{ object.link }}</a>
                    <div class="d-flex flex-column">
                    <span class="text-secondary" v-if="object.best_place">
                      Лучшая позиция в поиске:
                      <b class="text-dark">{{ object.best_place }}</b>
                    </span>
                      <span class="text-secondary" v-if="object.links_in_top20">Количество ссылок в поиске: <b
                          class="text-dark">{{ object.links_in_top20 }}</b></span>
                      <span class="text-secondary">Дата: <b
                          class="text-dark">{{ object.date }}</b></span>
                      <br>
                      <button class="btn btn-primary btn-sm" style="max-width: 150px;" @click="response=object">
                        Подробнее <i
                          class="bi bi-caret-down"></i></button>
                    </div>
                  </td>
                  <td class="col">
                    <ResponseChart v-if="object.link_obj_id" v-bind:link_id="object.link_obj_id"></ResponseChart>
                  </td>
                </tr>
              </template>
              <tr v-if="!objects.length">
                <td colspan="6">
                  Не найдено сайтов отзовиков
                </td>
              </tr>

              </tbody>
            </table>

          </div>
        </div>
        <PagerList v-bind:pager="pager" v-bind:updatePage="updatePage" v-bind:page="page"></PagerList>
      </template>


    </div>


  </main>
</template>

<script>
import dataMixin from './mixins.js';
import {DictionaryApi} from '@/api/dictionary'

export default {
  name: 'ResponsesService',
  mixins: [dataMixin],
  data() {
    return {
      name: 'review_sites',
      responses: [],
      link_obj_id: 285365,
      response: null,
      response_size: 20,
      response_all_count: 0,
      response_page: 1,
      is_positive: false
    }
  },
  computed: {
    response_pager() {
      return this.response_pager = this.$func.getPagerArray(this.response_all_count, this.response_size, this.response_page)
    }
  },
  watch: {
    response() {
      if (this.response) this.getResponse()
    },
    is_positive() {
      if (this.response) this.getResponse()
    },
    response_page() {
      if (this.response) this.getResponse()
    }
  },
  methods: {
    clearResponse() {
      this.response = null;
      this.response_all_count = 0;
      this.response_page = 1;
      this.is_positive = false;
    },
    getResponse() {
      let $this = this;
      console.log(this.response_size)

      DictionaryApi.get_list("reviews",
          this.response_page,
          this.response_size,
          `review_site=${this.response.id}&is_positive=${this.is_positive}`).then((response) => {
        $this.responses = response.results
        $this.response_all_count = response.count;
      })
    },
    updateResponsePage(page) {
      this.response_page = page
    }
  }
}
</script>
<style>
textarea {
  min-width: 250px;
}
</style>


