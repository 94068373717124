<template>

  <main class="text-center">
    <div class="form-signin">
      <div class="breadcrumb">
        <a :href="$store.getters.current_domain">Главная</a> <span>Авторизация</span>
      </div>
      <form @submit.prevent="login">

        <h1 class="h3 mb-3 fw-normal">Авторизация</h1>
        <div :class="'form-floating ' + ($v.username.$error ?'form-group--error':username.length?'form-group--success':'')">
          <input type="email" name="email" class="form-control" v-model.trim="$v.username.$model" placeholder="Email">
          <label>Email</label>
        </div>
        <div class="form-group__message">
        <div class="error" v-if="!$v.username.required && $v.username.$error">- Введите Email</div>
        <div class="error" v-if="!$v.username.mustBeEmail && $v.username.$error && username.length">
          - Неверный формат email адреса
        </div>
        </div>
        <div :class="'form-floating mt-2 ' + ($v.password.$error?'form-group--error':password.length?'form-group--success':'')">
          <input class="form-control" name="password" :type="show_pass?'text':'password'" v-model.trim="$v.password.$model"
                 placeholder="Пароль">
          <label>Пароль</label>
          <i v-on:click="show_pass=!show_pass" :class="`bi bi-eye${show_pass?' active':''}`"></i>
        </div>
        <div class="error" v-if="!$v.password.required && $v.password.$error">- Введите пароль</div>

        <div class="checkbox mb-3">
          <label>
            <input type="checkbox" v-model="remember" value="remember-me"> Запомнить
          </label>
        </div>
        <button class="w-100 btn btn-lg btn-primary" type="submit">Вход</button>
      </form>
      <div class="mt-3">
        <router-link to="/forgot_password/">Забыли пароль?</router-link> | <router-link to="/registration/">Регистрация</router-link>
      </div>
    </div>

    <modal name="popup_errors" class="modal-dialog" :adaptive="true" :height="'auto'" :max-width="600" style="position: absolute;"> 
        <div class="modal-content" style="padding: 15px">
          <div class="modal-header">
            <button type="button" class="btn-close" @click="$modal.hide('popup_errors');" data-bs-dismiss="modal"
                    aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div v-if="errors.length">
              <h5 v-for="error in errors">{{error}}</h5>
              </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="$modal.hide('popup_errors');"
                    data-bs-dismiss="modal">Закрыть
            </button>
          </div>
        </div>
      </modal>
    
  </main>

</template>
<script>
  import {required} from 'vuelidate/lib/validators'

  const mustBeEmail = (value) => /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(value);
  export default {

    name: 'UserLogin',
    data() {
      return {
        password: "",
        username: "",
        show_pass: false,
        errors: []
      }
    },
    validations: {
      username: {
        required,
        mustBeEmail,
      },
      password: {
        required
      },
    },
    computed: {
      remember: {
        get() {
          return localStorage.getItem('remember')
        },
        set(value) {
          this.$store.commit('setRemember', value)
        }
      },
      is_auth: function(){
          return this.$store.getters.is_auth;
      },
    },
    watch: {
      is_auth: {
        deep: true,
        handler(val) {
          if (val) this.$router.push('/');
        }

      }
    },
    mounted: function () {
      if (localStorage.getItem('username')) this.username = localStorage.getItem('username');
      if (localStorage.getItem('is_auth')) this.$router.push('/');
    },
    methods: {
      login: function (e) {
        e.preventDefault();
        this.errors = [];
        this.$v.$touch();
        if (!this.$v.$invalid) {
          let username = this.username, password = this.password, $this = this;
          this.$store.dispatch('login', {
            username,
            password
          }).then(() => {
//            $this.$router.push('/');
          }).catch(() => {
              $this.$notify({
                group: 'alert',
                type: 'error ',
                text: 'Данные неверны'
              })
          })
        }
      }
    }
  }
</script>
<style scoped>

  main {
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #f5f5f5;
    height: 100%;
  }

  .form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
  }

  .form-signin .checkbox {
    font-weight: 400;
  }

  .form-signin .form-floating:focus-within {
    z-index: 2;
  }

  .form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }


</style>
