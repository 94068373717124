<template>


  <nav id="sidebarMenu" :class="`d-md-block bg-white sidebar collapse h100 ${hide?'hiddenly':''}`">
    <button class="btn" @click="hide=!hide">
        <i v-if="!hide" class="bi bi-arrow-left-square"></i>
        <i v-else class="bi bi-arrow-right-square"></i>
      </button>
    <div class="position-sticky pt-3">
      <div class="menu">
        <ul class="list-unstyled menu ps-2 pb-4">
          <li v-for="page in $store.getters.pages" class="mb-1">
            <template v-if="page.app == 'profile'">
              <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
                      :data-bs-target="`#cp`" aria-expanded="false">
                Цифровой профиль
              </button>
              <div class="collapse" id="cp">
                <ul>
                  <li class="mb-1" v-for="project in projects">
                    <router-link active-class="active" class="link-dark rounded" :to="`/projects/${project.id}/`">
                            {{project.name}}
                          </router-link>
<!--                    <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"-->
<!--                            :data-bs-target="`#project${project.id}`" aria-expanded="false">-->
<!--                      {{project.name}}-->
<!--                    </button>-->
<!--                    <div class="collapse" :id="`project${project.id}`">-->
<!--                      <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">-->
<!--                        <li>-->
<!--                          <router-link active-class="active" class="link-dark rounded" :to="`/projects/${project.id}/`">-->
<!--                            Аналитика-->
<!--                          </router-link>-->
<!--                          &lt;!&ndash;<ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small ms-3">&ndash;&gt;-->
<!--                          &lt;!&ndash;<li>&ndash;&gt;-->
<!--                          &lt;!&ndash;<router-link active-class="active" :to="`/projects/${project.id}/`">&ndash;&gt;-->
<!--                          &lt;!&ndash;Органика&ndash;&gt;-->
<!--                          &lt;!&ndash;</router-link>&ndash;&gt;-->
<!--                          &lt;!&ndash;</li>&ndash;&gt;-->
<!--                          &lt;!&ndash;&lt;!&ndash;<li>&ndash;&gt;&ndash;&gt;-->
<!--                          &lt;!&ndash;&lt;!&ndash;<router-link active-class="active" :to="`/projects/${project.id}/images/`">&ndash;&gt;&ndash;&gt;-->
<!--                          &lt;!&ndash;&lt;!&ndash;Изображения&ndash;&gt;&ndash;&gt;-->
<!--                          &lt;!&ndash;&lt;!&ndash;</router-link>&ndash;&gt;&ndash;&gt;-->
<!--                          &lt;!&ndash;&lt;!&ndash;</li>&ndash;&gt;&ndash;&gt;-->
<!--                          &lt;!&ndash;</ul>&ndash;&gt;-->

<!--                        </li>-->
<!--                        <li>-->
<!--                          <router-link :to="`/influences/${project.id}/`" active-class="active"-->
<!--                                       class="link-dark rounded">Влияние-->
<!--                          </router-link>-->
<!--                        </li>-->
<!--                      </ul>-->
<!--                    </div>-->
                  </li>
                </ul>
              </div>
            </template>
            <template v-else>
              <router-link active-class="active" class="link-dark rounded" :to="`/${page.slug}/`">
                {{page.name}}
            </router-link>

            </template>
          </li>
        </ul>
      </div>
    </div>


  </nav>

</template>

<script>
  export default {
    name: 'left_menu',
     data() {
      return {
        hide: false
      }
    },
    computed: {
      projects() {
        return this.$store.getters.projects;
      },
    },
    mounted: function () {
      document.querySelectorAll(".menu .link-dark.rounded").forEach(item => {
        item.addEventListener('click', () => {
          document.getElementById('sidebarMenu').classList.remove("show");
        })
      })
    },
    methods: {
    }
  }
</script>
<style scoped>
  #sidebarMenu {
    box-shadow: 0 0 20px 6px rgba(0, 0, 0, 0.05);
    height: 100vh;
    position: fixed;
    padding: 0;
    width: 269px;
    overflow-y: auto;
  }

  .search {
    margin-top: 70px !important;
    margin-bottom: 60px !important;
  }

  #sidebarMenu .search, #sidebarMenu .nav-item a {
    margin: 15px 30px;
  }

  .nav-link {
    border-bottom: 1px solid #E2E2E2;
  }

  .nav-item a.active {
    margin-left: 0 !important;
    border-radius: 0 8px 8px 0;
  }

  .menu li a, .menu li button{
        display: inline-flex;
    align-items: center;
    padding: .25rem .5rem;
    font-weight: 600;
    color: rgba(0, 0, 0, .65);
    background-color: transparent;
    border: 0;
    text-decoration: none;
    position: relative;
    font-size: 0.8rem;
  }
  .menu li a.router-link-exact-active{
    color: #000;
    text-decoration: underline;
  }
  .menu li{
    list-style: none;
  }
  .menu .btn-toggle::before{
    position: absolute;
    left: -10px;
  }

  #sidebarMenu.hiddenly{
    width: 45px;
  }

  #sidebarMenu.hiddenly .position-sticky{
    display: none;
  }

</style>
