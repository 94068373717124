<template>
  <main class="dashboard">
    <div
        class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">{{ project.name }}: Анализ проекта</h1>
      <BackButton></BackButton>
    </div>

    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-md-5">
            <label for="country" class="form-label">Ключевые слова</label>
            <select class="form-select" v-model="keyword" id="country" required="">
              <option value="">Выбрать...</option>
              <option v-for="key in keywords" :value="key.id">{{ key.name }}</option>
            </select>
          </div>
          <div class="col-md-2">
            <label class="form-label">Дата</label>

            <date-picker style="width: 150px" v-model="active_date" ref="start_date" :config="options"></date-picker>
          </div>
          <div class="col-md-2">
            <label for="country" class="form-label">Страны</label>
            <CountryChoice v-bind:active_country.sync="active_country"
                           v-bind:project_id="$route.params.id"
                           v-bind:countries="project.countries"
                           v-bind:changeCountry="changeCountry"></CountryChoice>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">

            <div class="table-responsive">
              <table class="table table-bordered table-sm">
                <tbody>
                <tr class="table-active">
                  <th>ТОП100</th>
                  <th></th>
                </tr>
                <tr>
                  <td>Общее количество негатива</td>
                  <td>{{ stat.negative_100 }}</td>
                </tr>
                <tr>
                  <td>Общий процент негатива</td>
                  <td>{{ stat.negative_100_percent }}</td>
                </tr>
                <tr>
                  <td>Негатив в Яндекс</td>
                  <td>{{ stat.negative_100_y }}</td>
                </tr>
                <tr>
                  <td>Негатив в Google</td>
                  <td>{{ stat.negative_100_g }}</td>
                </tr>
                <tr>
                  <td>Уникальный негатив</td>
                  <td>{{ stat.negative_100_uniq }}</td>
                </tr>
                <tr>
                  <td>Уникальный в Яндекс</td>
                  <td>{{ stat.negative_100_uniq_y }}</td>
                </tr>
                <tr>
                  <td>Уникальный в Google</td>
                  <td>{{ stat.negative_100_uniq_g }}</td>
                </tr>
                <tr>
                  <td>Процент в Яндекс</td>
                  <td>{{ stat.negative_100_percent_y }}</td>
                </tr>
                <tr>
                  <td>Процент в Google</td>
                  <td>{{ stat.negative_100_percent_g }}</td>
                </tr>
                <tr>
                  <td>Количество нерелеванта</td>
                  <td>{{ stat.negative_100_no }}</td>
                </tr>

                <tr class="table-active">
                  <th>ТОП50</th>
                  <th></th>
                </tr>
                <tr>
                  <td>Общее количество негатива</td>
                  <td>{{ stat.negative_50 }}</td>
                </tr>
                <tr>
                  <td>Общий процент негатива</td>
                  <td>{{ stat.negative_50_percent }}</td>
                </tr>
                <tr>
                  <td>Негатив в Яндекс</td>
                  <td>{{ stat.negative_50_y }}</td>
                </tr>
                <tr>
                  <td>Негатив в Google</td>
                  <td>{{ stat.negative_50_g }}</td>
                </tr>
                <tr>
                  <td>Уникальный негатив</td>
                  <td>{{ stat.negative_50_uniq }}</td>
                </tr>
                <tr>
                  <td>Уникальный в Яндекс</td>
                  <td>{{ stat.negative_50_uniq_y }}</td>
                </tr>
                <tr>
                  <td>Уникальный в Google</td>
                  <td>{{ stat.negative_50_uniq_g }}</td>
                </tr>
                <tr>
                  <td>Процент в Яндекс</td>
                  <td>{{ stat.negative_50_percent_y }}</td>
                </tr>
                <tr>
                  <td>Процент в Google</td>
                  <td>{{ stat.negative_50_percent_g }}</td>
                </tr>
                <tr>
                  <td>Количество нерелеванта</td>
                  <td>{{ stat.negative_50_no }}</td>
                </tr>


                <tr class="table-active">
                  <th>ТОП20</th>
                  <th></th>
                </tr>
                <tr>
                  <td>Общее количество негатива</td>
                  <td>{{ stat.negative_20 }}</td>
                </tr>
                <tr>
                  <td>Общий процент негатива</td>
                  <td>{{ stat.negative_20_percent }}</td>
                </tr>
                <tr>
                  <td>Негатив в Яндекс</td>
                  <td>{{ stat.negative_20_y }}</td>
                </tr>
                <tr>
                  <td>Негатив в Google</td>
                  <td>{{ stat.negative_20_g }}</td>
                </tr>
                <tr>
                  <td>Уникальный негатив</td>
                  <td>{{ stat.negative_20_uniq }}</td>
                </tr>
                <tr>
                  <td>Уникальный в Яндекс</td>
                  <td>{{ stat.negative_20_uniq_y }}</td>
                </tr>
                <tr>
                  <td>Уникальный в Google</td>
                  <td>{{ stat.negative_20_uniq_g }}</td>
                </tr>
                <tr>
                  <td>Процент в Яндекс</td>
                  <td>{{ stat.negative_20_percent_y }}</td>
                </tr>
                <tr>
                  <td>Процент в Google</td>
                  <td>{{ stat.negative_20_percent_g }}</td>
                </tr>
                <tr>
                  <td>Количество нерелеванта</td>
                  <td>{{ stat.negative_20_no }}</td>
                </tr>


                <tr class="table-active">
                  <th>ТОП10</th>
                  <th></th>
                </tr>
                <tr>
                  <td>Общее количество негатива</td>
                  <td>{{ stat.negative_10 }}</td>
                </tr>
                <tr>
                  <td>Общий процент негатива</td>
                  <td>{{ stat.negative_10_percent }}</td>
                </tr>
                <tr>
                  <td>Негатив в Яндекс</td>
                  <td>{{ stat.negative_10_y }}</td>
                </tr>
                <tr>
                  <td>Негатив в Google</td>
                  <td>{{ stat.negative_10_g }}</td>
                </tr>
                <tr>
                  <td>Уникальный негатив</td>
                  <td>{{ stat.negative_10_uniq }}</td>
                </tr>
                <tr>
                  <td>Уникальный в Яндекс</td>
                  <td>{{ stat.negative_10_uniq_y }}</td>
                </tr>
                <tr>
                  <td>Уникальный в Google</td>
                  <td>{{ stat.negative_10_uniq_g }}</td>
                </tr>
                <tr>
                  <td>Процент в Яндекс</td>
                  <td>{{ stat.negative_10_percent_y }}</td>
                </tr>
                <tr>
                  <td>Процент в Google</td>
                  <td>{{ stat.negative_10_percent_g }}</td>
                </tr>
                <tr>
                  <td>Количество нерелеванта</td>
                  <td>{{ stat.negative_10_no }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


    </div>

  </main>
</template>

<script>
import {ProjectApi} from '@/api/project'
import datePicker from 'vue-bootstrap-datetimepicker';
import moment from 'moment';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import CountryChoice from "@/components/CountryChoice.vue";

export default {
  name: 'ProjectStat',
  components: {
    datePicker, CountryChoice
  },

  data() {
    return {
      active_date: null,
      old_active_date: null,
      state: 10,
      project: {},
      keywords: [],
      active_country: {code: null, yandex: true, google: true},
      stat: {},
      keyword: null,
      options: {
        format: 'DD/MM/YYYY',
        locale: 'ru',
        useCurrent: true,
        enabledDates: [],
        ignoreReadonly: true
      },
    }
  },
  computed: {
    date_query() {
      return this.active_date ? moment(this.active_date, 'DD/MM/YYYY').format('YYYY-MM-DD') : false;
    },
  },
  watch: {
    keyword: function () {
      this.fillData(this.project.id)
    },
    active_date: function (val) {
      if (this.old_active_date !== null) this.fillData(this.project.id)
      this.old_active_date = val;
    }
  },
  mounted: function () {
    let $this = this;
    ProjectApi.get_avail_date($this.$route.params.id, $this.date_query).then(response => {
      $this.options.enabledDates = response;
    });
    ProjectApi.get($this.$route.params.id, $this.date_query).then(response => {
      $this.project = response;
      $this.active_country = $this.project.countries[0];
      if (!$this.active_date) {
        $this.active_date = $this.$func.momentDate($this.project.last_parser ?
            $this.project.last_parser : $this.project.date_create, 'DD/MM/YYYY');
      }
      this.getKeywords();
      this.fillData(this.project.id);

    })

  },
  methods: {
    changeCountry: function (country) {
      if (this.active_country.code !== country.code) {
        this.active_country = country;
        this.getKeywords();
        this.fillData(this.project.id);
      }
    },
    getKeywords: function () {
      let $this = this;
      ProjectApi.light_keywords($this.$route.params.id, $this.active_country.code || 'RU').then(response => {
        $this.keywords = response;
      })
    },
    fillData: function (id) {
      let $this = this;
      ProjectApi.full_stat(id,
          {
            keyword: $this.keyword,
            date: $this.date_query,
            country_code: $this.active_country.code || 'RU'
          }
      ).then(response => {
        $this.stat = response;
      })
    },


  }
}

</script>

<style>
.date {
  height: 100px;
  margin: 20px 0;
}

.link {
  cursor: pointer;
}

td {
  max-width: 400px;
}
</style>

