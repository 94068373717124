import { HTTP } from './common'

export const ProjectApi = {
  list() {
    return HTTP.get(`user/projects/`).then((response) => {
      return response.data;
    });
  },

  get_tr_state(id) {
    return HTTP.get(`user/projects/${id}/get_tr_state/`).then((response) => {
      return response.data;
    });
  },


  get(id, date, country_code) {
    return HTTP.get(`user/projects/${id}/?${date?`date=${date}`:''}&${country_code?`country_code=${country_code}`:''}`).then((response) => {
      return response.data;
    });
  },

  get_snapshot(id, date=false){
    return HTTP.get(`user/projects/${id}/get_snapshot/?${date?`date=${date}`:''}`).then((response) => {
      return response.data;
    });
  },

  get_project_list() {
    return HTTP.get(`user/projects/get_project_list/`).then((response) => {
      return response.data;
    });
  },

  update_snapshot(id, data) {
    return HTTP.post(`user/projects/${id}/update_snapshot/`, data).then(response => {
      return response.data
    })
  },

  countries() {
    return HTTP.get(`countries/`).then((response) => {
      return response.data;
    });
  },
  get_for_edit(id) {
    return HTTP.get(`user/projects/${id}/edit/`).then(response => {
      return response.data
    })
  },
  update (object) {
    if (object.id) return HTTP.put(`projects/${object.id}/`, object).then(response => {
      return response.data
    });
    else return HTTP.post('user/projects/', object).then(response => {
      return response.data
    })
  },

  delete_keyword (id, key_id) {
    return HTTP.delete(`user/projects/${id}/delete_keyword/?key=${key_id}`,).then(response => {
      return response.data
    })
  },

   keywords(id, state, date, country_code, per_page, page) {
    return HTTP.get(
      `user/projects/${id}/keywords/?state=${state}&date=${date}&country_code=${country_code}&size=${per_page}&page=${page}`
    ).then((response) => {
      return response.data;
    });
  },

  light_keywords (id, country_code) {
    return HTTP.get(`user/projects/${id}/keywords/?country_code=${country_code}`).then(response => {
      return response.data
    })
  },

  get_full (id, date, country_code=false) {
    return HTTP.get(`user/projects/${id}/get_full/?date=${date}&country_code=${country_code}`).then(response => {
      return response.data
    })
  },

  get_avail_date (id) {
    return HTTP.get(`user/projects/${id}/get_avail_date_calendar/`).then(response => {
      return response.data
    })
  },

  full_stat (id, data) {
    return HTTP.post(`user/projects/${id}/full_stat/`, data).then(response => {
      return response.data
    })
  },
  search_result_download(id, data) {
    return HTTP.post(`user/projects/${id}/search_result_download/`, data, {responseType: "blob"}).then(response => {
      return response.data
    })
  },
  project_download_info(id, data) {
    return HTTP.post(`user/projects/${id}/project_download_info/`, data, {responseType: "blob"}).then(response => {
      return response.data
    })
  },
  links_info (id, query) {
    return HTTP.post(`user/projects/${id}/links_info/`, query).then(response => {
      return response.data
    })
  },

  get_simple(id) {
    return HTTP.get(`user/projects/${id}/get_simple/`).then((response) => {
      return response.data;
    });
  },
};

