import { render, staticRenderFns } from "./Registartion.vue?vue&type=template&id=f9e4cbac&scoped=true"
import script from "./Registartion.vue?vue&type=script&lang=js"
export * from "./Registartion.vue?vue&type=script&lang=js"
import style0 from "./Registartion.vue?vue&type=style&index=0&id=f9e4cbac&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9e4cbac",
  null
  
)

export default component.exports